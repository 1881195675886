import { Box, CircularProgress, Modal, useMediaQuery } from "@mui/material";
import React, { useEffect, useState, useRef, useMemo   } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Snackbar } from "@mui/joy";
import moment from "moment";
import { CLOCK, QUESTIONS } from "../../constants/Mocktests";
import SwiftUniLogo from "../../assets/images/swiftuniLogoo.svg";
import {
  EndTest,
  GetAllMocktestQuestion,
  GetPendingMocktestQuestion,
  Timeout,
} from "../../Client/request";
import Writing from "./Writing/WritingFull";
import Speaking from "./Speaking/SpeakingFull";
import Reading from "./Reading/ReadingFull";
import Listening from "./Listening/ListeningFull";
import CompatibilityModal from "./CompatibilityModal";
import Welcome from "./Welcome";
import RemainingTime from "./RemainingTime";
import useStopwatch from "../../hooks/useStopwatch";
import useTimer from "../../hooks/useTimer";
import toast from "react-hot-toast";
import WelcomeWriting from "./WelcomeWriting";
// import { useStopwatchContext } from '../../hooks/StopwatchContext';

export default function MockTestFull() {
  const location = useLocation();
  const { Exam } = location.state || {};
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const isMobile = useMediaQuery("(max-width:450px)");
  const isLaptopTwo = useMediaQuery("(max-width:1000px)");
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(true);
  const [startingCategory, setStartingCategory] = useState(null);
  const [MockTestAttemptID, setMockTestAttemptID] = useState();
  const [snackbarModel, setsnackbarModel] = useState(false);
  const [Endloader, setEndloader] = useState(false);
  const [timeoutModel, setTimeoutModel] = useState(false);
  const [showRemainingTime, setShowRemainingTime] = useState(null);
  const [commulative, setCommulative] = useState(0);
  const [prevElapsedTime, setPrevElapsedTime] = useState(0);
  const [isTestStarted, setIsTestStarted] = useState(false);
  const [RemainingListeningTime, setRemainingListeningTime] = useState(0);
  const [isResuming, setIsResuming] = useState(false);
  const [sstAttemptedCount, setSSTAttemptedCount] = useState(0);
  const [summarizeSpokenTextCount, setSummarizeSpokenTextCount] = useState(0);
  const [sstTimeAdded, setSSTTimeAdded] = useState(false);
  // const [currentCategory, setCurrentCategory] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(
    questions.length > 0 ? questions[0].Category : null
  );
  
  const [isOnWelcomePage, setIsOnWelcomePage] = useState(true);
  const [toastShown, setToastShown] = useState(false);
  const [isPrevElapsedTimeAdded, setIsPrevElapsedTimeAdded] = useState(false);

 
  const {
    elapsedTime,
    startStopwatch,
    stopStopwatch,
    resetStopwatch,
    resetElapsedTime,
    addTime,
    questionsTime,
    resetQuestionTimer,
  } = useStopwatch();

  // const {
  //   elapsedTime,
  //   isActive,
  //   startStopwatch,
  //   stopStopwatch,
  //   resetElapsedTime,
  //   resetStopwatch,
  //   addTime,
  // } = useStopwatchContext();

  const {
    stopTimer,
  } = useTimer();



  useEffect(() => {
    if (!Exam) {
      toast.error("Redirecting...");
      navigate("/MockTest");
    }
  }, [Exam, navigate]);

  if (!Exam) {
    return null;
  }

  useEffect(() => {
    if (!isOnWelcomePage && !toastShown) {
      setTimeout(() => {
        {Exam.ResumeTestId ?
          (toast.success("Test resumed. Good luck!"))
          :
          (toast.success("Test started. Good luck!"))
        }
      }, 200);
      setToastShown(true);
    }
  }, [isOnWelcomePage, toastShown]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.12)",
    borderRadius: "24px",
    p: isMobile ? 2 : 4,
    height: "80vh",
    overflowY: "auto",
  };
  


  useEffect(() => {
    // console.log("Elapsed Time in Parent Component:", elapsedTime);
  }, [elapsedTime]);
  

  const individualTimes = JSON.parse(Exam.IndividualTimes);
  const {
    speaking_time,
    writing_time,
    reading_time,
    listening_time,
  } = individualTimes;

  const speakingTime = speaking_time;
  const writingTime = writing_time;
  const readingTime = reading_time;
  const listeningTime = listening_time;

  const totalSpeakingWritingTime = speakingTime + writingTime;

  useEffect(() => {
    console.log("Step:", step);
  }, [step]);

  useEffect(() => {
    console.log("Is Test Started:", isTestStarted);
  }, [isTestStarted]);


  // useEffect(() => {
  //   if (prevElapsedTime > 0) {
  //     addTime(prevElapsedTime); 
  //     // setCommulative(prevElapsedTime);
  //     setCommulative((prev) => prev + prevElapsedTime);
  //     console.log("Added Previous Elapsed Time to Stopwatch:", prevElapsedTime);
  //     // setPrevElapsedTime(0);
  //   }
    
  // }, [prevElapsedTime]);

// Helper function to handle elapsed time addition

const handleAddElapsedTime = (category) => {
  if (prevElapsedTime > 0 && !isPrevElapsedTimeAdded) {
    if (category === "Listening") {
      console.log(`Adding Previous Elapsed Time for Listening:`, prevElapsedTime);
    } else {
      console.log(`Adding Previous Elapsed Time for ${category}:`, prevElapsedTime);
    }
    addTime(prevElapsedTime);
    setCommulative((prev) => prev + prevElapsedTime);
    setIsPrevElapsedTimeAdded(true);
  } else {
    console.log(`Time not added for ${category}. Either already added or prevElapsedTime is 0.`);
  }
};


useEffect(() => {
  if (currentCategory !== "Listening") {
    handleAddElapsedTime(currentCategory);
  }
}, [prevElapsedTime, isPrevElapsedTimeAdded, currentCategory]);


  // useEffect to Reset the stopwatch for the new category
  useEffect(() => {
    if (questions.length === 0 || step <= 0 || isResuming) return;
    // Determine the current category
    const newQuestion = questions[step - 1];
    const newCategory = newQuestion?.Category;
    // Check if the category has changed
    if (currentCategory && newCategory !== currentCategory) {
      const newCategoryTime = getTimeLimit(newQuestion);
      // Reset the stopwatch for the new category
      // resetStopwatch(moment.duration(newCategoryTime, "minutes"));
      setCommulative(0);
      resetStopwatch(moment.duration(newCategoryTime, "minutes").asMilliseconds());
      console.log(`Category changed to: ${newCategory}. Stopwatch reset.`);
      setCurrentCategory(newCategory);
    }
  }, [step, questions, currentCategory]);

  useEffect(() => {
    if (isResuming && step > 0) {
      console.log("Resumption complete. Resetting isResuming...");
      setIsResuming(false); // Reset resuming state after step is updated
    }
  }, [isResuming, step]);

  useEffect(() => {
    if (isTestStarted) {
      startStopwatch();
      setIsTestStarted(false);
    }
  }, [isTestStarted]);

  useEffect(() => {
    if (questions.length > 0) {
      const sstQuestions = questions.filter((q) => q.Category === "Listening" && q.SubCategory === "Summarize Spoken Text");
      const totalSSTCount = sstQuestions.length;
      setSummarizeSpokenTextCount(totalSSTCount);

      const sumSpokenTextTime = totalSSTCount * 10;
      const listeningOtherQsTime = listeningTime - sumSpokenTextTime;
      setRemainingListeningTime(listeningOtherQsTime);

      const attemptedSSTCount = sstQuestions.filter((q) => q.IsAttempted).length;
      setSSTAttemptedCount(attemptedSSTCount);
    }
  }, [questions, listeningTime]);

  // // useEffect to detect all sst questions attempted, then reset stop watch
  // useEffect(() => {
  //     if (summarizeSpokenTextCount > 0 && !sstTimeAdded && sstAttemptedCount === summarizeSpokenTextCount) {
  //     // const sstTotalTime = summarizeSpokenTextCount * 10; 
  //     // addSSTTime(moment.duration(sstTotalTime, "minutes").asSeconds());
  //     // console.log(`Added SST Total Time: ${sstTotalTime} minutes for ${summarizeSpokenTextCount} SST questions.`);
  //     setSSTTimeAdded(true);

  //     if(prevElapsedTime > 0) {
  //       setCommulative(0);
  //       console.log(`SST Questions Done. prevElapsedTime Present. Stop Watch Reseting for Time: ${RemainingListeningTime}`);
  //       resetStopwatch(moment.duration(RemainingListeningTime, "minutes").asMilliseconds());
  //       addTime(prevElapsedTime);
  //       // setPrevElapsedTime(0); 
  //       // setCommulative(prevElapsedTime);
  //       setCommulative((prev) => prev + prevElapsedTime);
  //       if (!isOnWelcomePage) {
  //         console.log("Starting stopwatch from parent for remaining listening...");
  //         stopTimer();
  //         startStopwatch();
  //       }
  //     }
      
  //     else {
  //       setCommulative(0);
  //       console.log(`SST Questions Done. prevElapsedTime Not Present. Stop Watch Reseting for Time: ${RemainingListeningTime}`);
  //       resetStopwatch(moment.duration(RemainingListeningTime, "minutes").asMilliseconds());
  //       if (!isOnWelcomePage) {
  //         console.log("Starting stopwatch from parent for remaining listening...");
  //         stopTimer();
  //         startStopwatch();
  //       }
  //     }

  //   }
  // }, [sstAttemptedCount, summarizeSpokenTextCount, sstTimeAdded, RemainingListeningTime, isOnWelcomePage, prevElapsedTime, isResuming]);


  useEffect(() => {
    if (summarizeSpokenTextCount > 0 && !sstTimeAdded && sstAttemptedCount === summarizeSpokenTextCount) {

      setSSTTimeAdded(true);

      if (prevElapsedTime > 0 && !isPrevElapsedTimeAdded) {
        console.log(`SST Questions Done. prevElapsedTime Present. Stop Watch Reseting for Time: ${RemainingListeningTime}`);
        setCommulative(0);
        resetStopwatch(moment.duration(RemainingListeningTime, "minutes").asMilliseconds());
        handleAddElapsedTime(currentCategory);
        setIsPrevElapsedTimeAdded(true);
  
        if (!isOnWelcomePage) {
          console.log("Starting stopwatch from parent for remaining listening...");
          stopTimer();
          startStopwatch();
        }
      } 
      
      else {
        console.log(`SST Questions Done. prevElapsedTime Not Present. Stop Watch Reseting for Time: ${RemainingListeningTime}`);
        setCommulative(0);
        resetStopwatch(moment.duration(RemainingListeningTime, "minutes").asMilliseconds());
  
        if (!isOnWelcomePage) {
          console.log("Starting stopwatch from parent for remaining listening...");
          stopTimer();
          startStopwatch();
        }
      }
    }
  }, [
    sstAttemptedCount,
    summarizeSpokenTextCount,
    sstTimeAdded,
    RemainingListeningTime,
    isOnWelcomePage,
    prevElapsedTime,
    isPrevElapsedTimeAdded,
    isResuming,
    currentCategory,
  ]);
  
  // const getTimeLimit = (category) => {
  //   switch (category) {
  //     case "Speaking":
  //       return speakingTime;
  //     case "Writing":
  //       return writingTime;
  //     case "Reading":
  //       return readingTime;
  //     case "Listening":
  //       return RemainingListeningTime;
  //     default:
  //       return 0;
  //   }
  // };

  const getTimeLimit = (question) => {
    if (!question) return 0;
    const category = question.Category;
    const subCategory = question.SubCategory;
    switch (category) {
      case "Speaking":
        return speakingTime;
      case "Writing":
        return writingTime;
      case "Reading":
        return readingTime;
      case "Listening":
        if (subCategory === "Summarize Spoken Text") {
          return summarizeSpokenTextCount * 10; 
        } else {
          return RemainingListeningTime;
        }
      default:
        return 0;
    }
  };
  
  // //timeout useEffect
  // useEffect(() => {
  //   if (questions.length === 0) return;

  //   const currentCategory = questions[step - 1]?.Category;
  //   // const currentSubCategory = questions[step - 1]?.SubCategory;

  //   // Find the index where the category changes
  //   let endIndex = step;
  //   for (let i = step; i < questions.length; i++) {
  //     if (questions[i].Category !== currentCategory) {
  //       endIndex = i;
  //       break;
  //     }
  //   }

  //   // If no category change is found, set endIndex to the end of the questions array
  //   if (endIndex === step) {
  //     endIndex = questions.length;
  //   }

  //   const timeLimit = getTimeLimit(currentCategory);

  //   if (elapsedTime >= moment.duration(timeLimit, "minutes").asMilliseconds()) {
  //     // const initialCategory = currentCategory;
  //     if (!currentCategory) return;

  //     // Slice the remaining questions in the current category
  //     const slicedArray = questions.slice(step - 1, endIndex);

  //     // Call the timeout API for the remaining questions in the current category
  //     const payload = {
  //       mock_test_attempt_id: MockTestAttemptID
  //         ? MockTestAttemptID
  //         : Exam.ResumeTestId,
  //       is_ptecore: false,
  //       response_obj: JSON.stringify(slicedArray),
  //     };
  //     Timeout(payload);
  //     setTimeoutModel(true);

  //     if (endIndex >= questions.length) {
  //       // No more questions left, end the test
  //       setEndloader(true);
  //       const Endpayload = {
  //         mock_test_attempt_id: MockTestAttemptID
  //           ? MockTestAttemptID
  //           : Exam.ResumeTestId,
  //         end_time: new Date().toLocaleDateString(),
  //       };
  //       EndTest(Endpayload).then((res) => {
  //         if (res.data) {
  //           navigate(
  //             `/mt-score-viewscore-sectional/${
  //               MockTestAttemptID ? MockTestAttemptID : Exam.ResumeTestId
  //             }`
  //           );
  //           setEndloader(false);
  //         }
  //       });
  //     } else {
  //       // There are more questions in the next category
  //       // Move to the next category
  //       const FinalIndex = endIndex;
  //       setStep(FinalIndex + 1);

  //       // Determine the next category and reset the stopwatch
  //       const nextCategory = questions[FinalIndex]?.Category;
  //       const nextCategoryTime = getTimeLimit(nextCategory);
  //       // Reset the stopwatch with the time duration for the new category
  //       console.log('Found Next Category in Else Part of Timeout API:', nextCategory)
  //       console.log('Resting Time For This Category is:', nextCategoryTime)
  //       // resetStopwatch(moment.duration(nextCategoryTime, "minutes"));

  //       switch (nextCategory) {
  //         case "Speaking":
  //           setStartingCategory(1);
  //           break;
  //         case "Writing":
  //           setStartingCategory(2);
  //           break;
  //         case "Reading":
  //           setStartingCategory(3);
  //           break;
  //         case "Listening":
  //           setStartingCategory(4);
  //           break;
  //         default:
  //           break;
  //       }

  //       setIsTestStarted(false);
  //     }
  //   }
  // }, [elapsedTime]);

  useEffect(() => {
    //timeout useEffect
    // console.log("Elapsed Time in Paren Component:", elapsedTime);
    if (questions.length === 0 || step <= 0) return;

    const currentCategory = questions[step - 1]?.Category;
    const currentQuestion = questions[step - 1];
    // Find the index where the category changes
    let endIndex = step;
    for (let i = step; i < questions.length; i++) {
      if (questions[i].Category !== currentCategory) {
        endIndex = i;
        break;
      }
    }

    // If no category change is found, set endIndex to the end of the questions array
    if (endIndex === step) {endIndex = questions.length}

    // const timeLimit = getTimeLimit(currentCategory);
    const timeLimit = getTimeLimit(currentQuestion);

    if (elapsedTime >= moment.duration(timeLimit, "minutes").asMilliseconds()) {
      if (!currentCategory) return;
      // Call Timeout API only for the current category's unanswered questions
      const slicedArray = questions.slice(step - 1, endIndex);
      const payload = {
        mock_test_attempt_id: MockTestAttemptID
          ? MockTestAttemptID
          : Exam.ResumeTestId,
        is_ptecore: false,
        response_obj: JSON.stringify(slicedArray),
      };
      Timeout(payload);
      setTimeoutModel(true);

      // Move to the next category if questions remain
      if (endIndex < questions.length) {
        const FinalIndex = endIndex;
        setStep(FinalIndex + 1);

        const nextCategory = questions[FinalIndex]?.Category;

        switch (nextCategory) {
          case "Speaking":
            setStartingCategory(1);
            break;
          case "Writing":
            setStartingCategory(2);
            break;
          case "Reading":
            setStartingCategory(3);
            break;
          case "Listening":
            setStartingCategory(4);
            break;
          default:
            break;
        }
      } else {
        // End the test if no more questions remain
        setEndloader(true);
        const Endpayload = {
          mock_test_attempt_id: MockTestAttemptID
            ? MockTestAttemptID
            : Exam.ResumeTestId,
          end_time: new Date().toLocaleDateString(),
        };
        EndTest(Endpayload).then((res) => {
          if (res.data) {
            navigate(`/mt-score-viewscore/${MockTestAttemptID ? MockTestAttemptID : Exam.ResumeTestId}`);
            setEndloader(false);
          }
        });
      }
    }
  }, [elapsedTime]);

  useEffect(() => {
    if (Exam.ResumeTestId) {
      setIsResuming(true);
      // toast.success("You Resumed The Mock Test Exam.");
      GetPendingMocktestQuestion({ id: Exam.ResumeTestId }).then((res) => {
        if (res.responseCode === 200) {
          setQuestions(res.response || []);

          // NewIndex is the first unattempted question
          const NewIndex = res?.response.findIndex(
            (element) => element.IsAttempted === false
          );
          const startingCategory = res?.response[NewIndex]?.Category;
          setCurrentCategory(startingCategory);

          setStep(NewIndex + 1);


          // // Retrieve previous cumulative time
          // const previousElapsedTime = res?.response[NewIndex - 1]?.AllTimes
          // ? JSON.parse(res.response[NewIndex - 1].AllTimes)?.commulativeTime
          // : 0;
                    
          // console.log("previous Elapsed Time After Resume: ", previousElapsedTime);
          // if (previousElapsedTime > 0) {setPrevElapsedTime(previousElapsedTime);}


// Filter questions to include only those in the current category
const currentCategoryQuestions = res.response.filter(
  (q) => q.Category === startingCategory
);

// Special handling for Listening category
let lastAttemptedQuestion;

if (startingCategory === "Listening") {
  // Exclude "Summarize Spoken Text" questions
  const listeningQuestionsExcludingSST = currentCategoryQuestions.filter(
    (q) => q.SubCategory !== "Summarize Spoken Text"
  );

  // Find the last attempted question in Listening (excluding SST)
  lastAttemptedQuestion = listeningQuestionsExcludingSST
    .reverse() // Reverse to get the last attempted question first
    .find((q) => q.IsAttempted);
} else {
  // For other categories, find the last attempted question normally
  lastAttemptedQuestion = currentCategoryQuestions
    .reverse() // Reverse to get the last attempted question first
    .find((q) => q.IsAttempted);
}

// Retrieve cumulative time from the last attempted question
const cumulativeTime = lastAttemptedQuestion?.AllTimes
  ? JSON.parse(lastAttemptedQuestion.AllTimes)?.commulativeTime || 0
  : 0;

console.log("Cumulative Elapsed Time for Current Category:", cumulativeTime);

if (cumulativeTime > 0) {
  setPrevElapsedTime(cumulativeTime);
}


          switch (startingCategory) {
            case "Speaking":
              setStartingCategory(1);
              break;
            case "Writing":
              setStartingCategory(2);
              break;
            case "Reading":
              setStartingCategory(3);
              break;
            case "Listening":
              setStartingCategory(4);
              break;
            default:
              break;
          }
        }
      });
    } else {
      GetAllMocktestQuestion({ id: Exam?.MockTestId }).then((res) => {
        if (res?.responseCode === 200) {
          setMockTestAttemptID(res.response.MockTestAttemptId);
          setQuestions(res?.response?.obj || []);
          if (res.response.obj && res.response.obj.length > 0) {
            const NewIndex = 0;
            const startingCategory = res.response.obj[NewIndex]?.Category;
            setCurrentCategory(startingCategory);
            switch (startingCategory) {
              case "Speaking":
                setStartingCategory(1);
                break;
              case "Writing":
                setStartingCategory(2);
                break;
              case "Reading":
                setStartingCategory(3);
                break;
              case "Listening":
                setStartingCategory(4);
                break;
              default:
                break;
            }
          }
        } else if (res.responseCode === 400) {
          setsnackbarModel(true);
          setTimeout(() => navigate("/MockTest"), 1000);
        }
      });
    }
  }, []);

  // useEffect to handle timers based on step and questions
  useEffect(() => {
    if (questions.length === 0 || step <= 0) return;

    const currentQuestion = questions[step - 1];
    const currentCategory = currentQuestion?.Category;
    const currentSubCategory = currentQuestion?.SubCategory;

    if (currentCategory === "Writing") {
      let timeDuration = 0;
      if (currentSubCategory === "Write Essay") timeDuration = 20;
      else if (currentSubCategory === "Summarize Written Text") timeDuration = 10;
      else if (currentSubCategory === "Write Email") timeDuration = 9;
      setShowRemainingTime(timeDuration);
    } else if (currentCategory === "Listening" && currentSubCategory === "Summarize Spoken Text") {
      setShowRemainingTime(10);
    } else {
      setShowRemainingTime(null);
    }
  }, [step, questions]);



  useEffect(() => {
    if (questions.length === 0 || step <= 0) return;
    const currentCategory = questions[step - 1]?.Category;
    const isWelcomePage =
      (currentCategory === "Speaking" && startingCategory === 1) ||
      (currentCategory === "Writing" && startingCategory === 2) ||
      (currentCategory === "Reading" && startingCategory === 3) ||
      (currentCategory === "Listening" && startingCategory === 4);
    setIsOnWelcomePage(isWelcomePage);
  }, [step, startingCategory, questions]);

  const formatElapsedTime = (elapsedTime) => {
    return elapsedTime >= 3600000
      ? moment.utc(elapsedTime).format("HH:mm:ss")
      : moment.utc(elapsedTime).format("mm:ss");
  };

  const getFormattedTotalTime = (category) => {
    const totalMinutes =
        category === "Speaking"  
        ? speakingTime
        : category === "Writing"  
        ? writingTime
        : category === "Reading"   
        ? readingTime
        : category === "Listening" 
        ? RemainingListeningTime
        : 0;

    const totalMilliseconds = totalMinutes * 60 * 1000;
    return totalMilliseconds >= 3600000
      ? moment.utc(totalMilliseconds).format("HH:mm:ss")
      : moment.utc(totalMilliseconds).format("mm:ss");
  };


  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={4000}
        open={timeoutModel}
        variant="solid"
        color="danger"
        onClose={() => setTimeoutModel(false)}
      >
        Time ended for this section
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={4000}
        open={snackbarModel}
        variant="soft"
        color="danger"
        onClose={() => setsnackbarModel(false)}
      >
        You have already started this test !!
      </Snackbar>
      <Modal
        open={open}
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          backdropFilter: "blur(3px)",
        }}
        onClose={(event, reason) => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return;
          }
          setOpen(false);
        }}
      >
        <Box sx={style}>
          <CompatibilityModal setOpen={setOpen} test={Exam} />
        </Box>
      </Modal>
      {/* header */}
      {questions.length > 0 && (
        <div
          style={{
            padding: isLaptopTwo ? "15px 10px" : "5px 100px",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <img src={SwiftUniLogo} alt="logo" />
            {!isMobile && <h1>Mock Test</h1>}
          </div>

          {/* <span>
            {formatElapsedTime(elapsedTime)} / 
            {questions.length > 0 && getFormattedTotalTime(questions[step - 1]?.Category)}
          </span> */}

          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div style={{ display: "flex", gap: "10px" }}>
              {CLOCK}
              {showRemainingTime ? (
                <RemainingTime
                  showRemainingTime={showRemainingTime}
                  setShowRemainingTime={setShowRemainingTime}
                  stopStopwatch={stopStopwatch}
                  addTime={addTime}
                  Category={questions[step - 1].Category}
                  step={step}
                  setStep={setStep}
                  startingCategory={startingCategory}
                  questions={questions}
                  isOnWelcomePage={isOnWelcomePage}
                />
              ) : (
                <span>
                  {formatElapsedTime(elapsedTime)} / 
                  {questions.length > 0 && getFormattedTotalTime(questions[step - 1]?.Category)}
                </span>
              )}
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              {QUESTIONS}
              <span>
                {step} of {questions.length}
              </span>
            </div>
          </div>
        </div>
      )}
      {Endloader ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            width: "100vw",
            height: "80vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : questions.length > 0 ? (
        <>
          {questions[step - 1].Category === "Speaking" ? (
            startingCategory === 1 ? (
              <Welcome
                Exam={Exam.MockTestName}
                startingCategory={startingCategory}
                setNextStartingCategory={setStartingCategory}
                part={1}
                name={"Speaking & Writing"}
                totalTime={totalSpeakingWritingTime}
                speakingTime={speakingTime}
                writingTime={writingTime}
                setIsTestStarted={setIsTestStarted}
                isResuming={!!Exam.ResumeTestId}
                questions={questions}
                step={step}
              />
            ) : (
              <Speaking
                ExamId={Exam.ResumeTestId}
                quest={questions[step - 1]}
                step={step}
                setStep={setStep}
                MockTestAttemptID={MockTestAttemptID}
                totalQuestions={questions.length}
                commulative={commulative}
                setCommulative={setCommulative}

                elapsedTime={questionsTime}
                startStopwatch={startStopwatch}
                stopStopwatch={stopStopwatch}
                resetElapsedTime={resetQuestionTimer}
              />
            )
          ) : questions[step - 1].Category === "Writing" ? (
            startingCategory === 2 ? (
              <WelcomeWriting
                Exam={Exam.MockTestName}
                startingCategory={startingCategory}
                setNextStartingCategory={setStartingCategory}
                name={"Writing"}
                writingTime={writingTime}
                setIsTestStarted={setIsTestStarted}
                isResuming={!!Exam.ResumeTestId}
                questions={questions}
                step={step}
              />
            ) : (
              <Writing
                ExamId={Exam.ResumeTestId}
                quest={questions[step - 1]}
                step={step}
                setStep={setStep}
                MockTestAttemptID={MockTestAttemptID}
                totalQuestions={questions.length}
                isResuming={!!Exam.ResumeTestId}

                elapsedTime={questionsTime}
                startStopwatch={startStopwatch}
                stopStopwatch={stopStopwatch}
                resetElapsedTime={resetQuestionTimer}
              />
            )
          ) : questions[step - 1].Category === "Reading" ? (
            startingCategory === 3 ? (
              <Welcome
                Exam={Exam.MockTestName}
                startingCategory={startingCategory}
                setNextStartingCategory={setStartingCategory}
                part={2}
                name={"Reading"}
                setCommulative={setCommulative}
                totalTime={readingTime}
                setIsTestStarted={setIsTestStarted}
                isResuming={!!Exam.ResumeTestId}
                questions={questions}
                step={step}
              />
            ) : (
              <Reading
                ExamId={Exam.ResumeTestId}
                quest={questions[step - 1]}
                step={step}
                setStep={setStep}
                MockTestAttemptID={MockTestAttemptID}
                totalQuestions={questions.length}
                commulative={commulative}
                setCommulative={setCommulative}

                elapsedTime={questionsTime}
                startStopwatch={startStopwatch}
                stopStopwatch={stopStopwatch}
                resetElapsedTime={resetQuestionTimer}
              />
            )
          ) : questions[step - 1].Category === "Listening" ? (
            startingCategory === 4 ? (
              <Welcome
                Exam={Exam.MockTestName}
                startingCategory={startingCategory}
                setNextStartingCategory={setStartingCategory}
                part={3}
                name={"Listening"}
                totalTime={listeningTime}
                RemainingListeningTime={RemainingListeningTime}
                summarizeSpokenTextTime={summarizeSpokenTextCount * 10}
                setIsTestStarted={setIsTestStarted}
                isResuming={!!Exam.ResumeTestId}
                questions={questions}
                step={step}
              />
            ) : (
              <Listening
                ExamId={Exam.ResumeTestId}
                quest={questions[step - 1]}
                step={step}
                setStep={setStep}
                MockTestAttemptID={MockTestAttemptID}
                totalQuestions={questions.length}
                commulative={commulative}
                setCommulative={setCommulative}
                incrementSSTAttemptedCount={() => setSSTAttemptedCount((prev) => prev + 1)}

                elapsedTime={questionsTime}
                startStopwatch={startStopwatch}
                stopStopwatch={stopStopwatch}
                resetElapsedTime={resetQuestionTimer}
              />
            )
          ) : null}
        </>
      ) : null}
    </div>
  );
}





// import { Box, CircularProgress, Modal, useMediaQuery } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { CLOCK, QUESTIONS } from "../../constants/Mocktests";
// import SwiftUniLogo from "../../assets/images/swiftuniLogoo.svg";
// import Writing from "./Writing/WritingFull";
// import Speaking from "./Speaking/SpeakingFull";
// import Reading from "./Reading/ReadingFull";
// import Listening from "./Listening/ListeningFull";
// import CompatibilityModal from "./CompatibilityModal";
// import {
//   EndTest,
//   GetAllMocktestQuestion,
//   GetPendingMocktestQuestion,
//   Timeout,
// } from "../../Client/request";
// import { useLocation, useNavigate } from "react-router-dom";
// import { PurpleBtn } from "../Common/Style";
// import Welcome from "./Welcome";
// import { Snackbar } from "@mui/joy";
// import moment from "moment";
// import useTimer from "../../hooks/useTimer";
// import RemainingTime from "./RemainingTime";
// import useStopwatch from "../../hooks/useStopwatch";

// export default function MockTest() {
//   const location = useLocation();
//   const { Exam } = location.state || {};
//   const navigate = useNavigate();
//   const [questions, setQuestions] = useState([]);
//   const isMobile = useMediaQuery("(max-width:450px)");
//   const isLaptopTwo = useMediaQuery("(max-width:1000px)");
//   const [step, setStep] = useState(1);
//   const [open, setOpen] = useState(true);
//   const [InitStep, setInitStep] = useState(1);
//   const [MockTestAttemptID, setMockTestAttemptID] = useState();
//   const [snackbarModel, setsnackbarModel] = useState(false);
//   const [Endloader, setEndloader] = useState(false);
//   const [timeoutModel, setTimeoutModel] = useState(false);
//   const [showRemainingTime, setShowRemainingTime] = useState(null);
//   const [commulative, setCommulative] = useState(0);
//   const [InitTimer, setInitTimer] = useState(0);
//   const [isTestStarted, setIsTestStarted] = useState(false);
//   const [RemainingListeningTime, setRemainingListeningTime] = useState(0);
//   const [summarizeSpokenTextCount, setSummarizeSpokenTextCount] = useState(0);

//   const style = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: "80vw",
//     bgcolor: "background.paper",
//     // border: "2px solid #000",
//     boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.12)",
//     borderRadius: "24px",
//     p: isMobile ? 2 : 4,
//     height: "80vh", // maximum height
//     overflowY: "auto",
//   };

//   useEffect(() => {
//     if (isTestStarted) {
//       startStopwatch();
//       setIsTestStarted(false);
//     }
//   }, [isTestStarted]);

//   const individualTimes = JSON.parse(Exam.IndividualTimes);

//   const speakingTime = individualTimes.speaking_time;
//   const writingTime = individualTimes.writing_time;
//   const readingTime = individualTimes.reading_time;
//   const listeningTime = individualTimes.listening_time;

//   // const testTime = {
//   //   speaking: speakingTime,
//   //   writing: writingTime,
//   //   reading: readingTime,
//   //   listening: listeningTime,
//   // };

//   const totalSpeakingWritingTime = speakingTime + writingTime;

//   const {
//     elapsedTime,
//     isActive,
//     startStopwatch,
//     stopStopwatch,
//     resetStopwatch,
//     addTime,
//   } = useStopwatch(moment.duration(speakingTime, "minutes"));

//   // useEffect(() => {
//   //   if (isTestStarted) {
//   //     startStopwatch();
//   //   }
//   // }, [isTestStarted]);

//   useEffect(() => {
//     if (isTestStarted && !isActive) {
//       startStopwatch();
//     }
//   }, [isTestStarted, isActive]);

//   //Remaining Listening Time based on the number of "Summarize Spoken Text" questions
//   useEffect(() => {
//     if (questions.length > 0) {
//       let sumSpokenTextCount = questions.filter(
//         (q) =>
//           q.Category === "Listening" &&
//           q.SubCategory === "Summarize Spoken Text"
//       ).length;

//       setSummarizeSpokenTextCount(sumSpokenTextCount);

//       const adjustedListeningTime = listeningTime - sumSpokenTextCount * 10;
//       setRemainingListeningTime(adjustedListeningTime);
//     }
//   }, [questions, listeningTime]);

//   //timeout useEffect
//   useEffect(() => {
//     const timeCheck =
//       questions[step - 1]?.Category === "Reading"
//         ? readingTime
//         : questions[step - 1]?.Category === "Listening"
//         ? RemainingListeningTime
//         : totalSpeakingWritingTime;

//     // console.log("timecheck", moment.duration(timeCheck, "minutes"));

//     if (
//       questions.length > 0 &&
//       elapsedTime >= moment.duration(timeCheck, "minutes").asMilliseconds()
//     ) {
//       const initialCategory = questions[step]?.Category;
//       if (!initialCategory) return;

//       if (initialCategory === "Listening") {
//         // Call the timeout API
//         setEndloader(true);
//         const payload = {
//           mock_test_attempt_id: MockTestAttemptID,
//           is_ptecore: false,
//           response_obj: JSON.stringify(
//             questions.slice(step - 1, questions.length)
//           ),
//         };
//         Timeout(payload);
//         const Endpayload = {
//           mock_test_attempt_id: MockTestAttemptID
//             ? MockTestAttemptID
//             : Exam.ResumeTestId,
//           end_time: new Date().toLocaleDateString(),
//         };
//         EndTest(Endpayload).then((res) => {
//           if (res.data) {
//             // navigate("/MockTest"); //mt-score
//             console.log(
//               `Navigating to /mt-score-viewscore/${
//                 MockTestAttemptID ? MockTestAttemptID : Exam.ResumeTestId
//               }`
//             );
//             navigate(
//               `/mt-score-viewscore/${
//                 MockTestAttemptID ? MockTestAttemptID : Exam.ResumeTestId
//               }`
//             );
//             setEndloader(false);
//           }
//         });
//       } else {
//         // Slicing logic
//         let endIndex = step;
//         // Find the index where the category changes
//         for (let i = step; i < questions.length; i++) {
//           if (questions[i].Category !== initialCategory) {
//             if (
//               initialCategory === "Speaking" &&
//               questions[i].Category === "Writing"
//             ) {
//               continue;
//             } else {
//               endIndex = i;
//               break;
//             }
//           }
//         }

//         // If no category change is found, slice till the end of the array
//         if (endIndex === step) {
//           endIndex = questions.length;
//         }

//         // Slice the array
//         const slicedArray = questions.slice(step - 1, endIndex);

//         const payload = {
//           mock_test_attempt_id: MockTestAttemptID,
//           is_ptecore: false,
//           response_obj: JSON.stringify(slicedArray),
//         };
//         Timeout(payload);
//         setTimeoutModel(true);

//         const FinalIndex =
//           endIndex + 1 > questions.length ? endIndex - 1 : endIndex + 1;

//         setStep(FinalIndex);

//         switch (questions[FinalIndex].Category) {
//           case "Writing":
//             setInitStep(1);
//             break;
//           case "Reading":
//             resetStopwatch(moment.duration(readingTime, "minutes"));
//             setInitStep(2);
//             break;
//           case "Listening":
//             resetStopwatch(moment.duration(listeningTime, "minutes"));
//             setInitStep(3);
//             break;
//         }
//       }
//     }
//   }, [elapsedTime]);

//   useEffect(() => {
//     if (showRemainingTime) {
//       if (questions.length > 0 && step > 1) {
//         if (InitStep === 2) {
//           console.log("I AM HEREEEE FOR RESETTTTT!!!!");
//           resetStopwatch(moment.duration(readingTime, "minutes"));
//         } else if (InitStep === 3) {
//           resetStopwatch(moment.duration(listeningTime, "minutes"));
//         }
//       }
//     }
//   }, [showRemainingTime]);

//   // useEffect(() => {
//   //   if (InitTimer > 0) {
//   //     addTime(InitTimer);
//   //   }
//   // }, [InitTimer]);

//   useEffect(() => {
//     if (Exam.ResumeTestId) {
//       GetPendingMocktestQuestion({ id: Exam.ResumeTestId }).then((res) => {
//         if (res.responseCode === 200) {
//           setQuestions(res.response || []);

//           //newIndex is the the first false entry
//           const NewIndex = res.response.findIndex(
//             (element) => element.IsAttempted === false
//           );

//           setStep(NewIndex + 1);

//           // console.log("newindex",NewIndex,res.response[NewIndex - 1].Category,res.response[NewIndex-2].Category,res.response[NewIndex-2].QuestionId);
          
//           if (
//             NewIndex > 0 &&
//             res.response[NewIndex - 1].Category ===
//               res.response[NewIndex].Category
//           ) {
//             let previousElapsedTime = 0;
//             previousElapsedTime = JSON.parse(
//               res.response[NewIndex-1].AllTimes
//             )?.commulativeTime;
//             console.log("previos time",previousElapsedTime);
            
//             if (previousElapsedTime > 0) {
//               console.log("previousElapsedTime : ", previousElapsedTime);
//               setInitTimer(previousElapsedTime);
//               resetStopwatch();
//               addTime(previousElapsedTime);
//             }
//           }

//           switch (res.response[NewIndex].Category) {
//             case "Writing":
//               setInitStep(1);
//               break;
//             case "Reading":
//               setInitStep(2);
//               break;
//             case "Listening":
//               setInitStep(3);
//               break;
//           }
//         }
//       });
//     } else {
//       GetAllMocktestQuestion({ id: Exam.MockTestId }).then((res) => {
//         if (res.responseCode === 200) {
//           setMockTestAttemptID(res.response.MockTestAttemptId);
//           setQuestions(res.response.obj || []);
//         } else if (res.responseCode === 400) {
//           setsnackbarModel(true);
//           setTimeout(() => navigate("/MockTest"), 1000);
//         }
//       });
//     }
//   }, []);

//   useEffect(() => {
//     if (questions && step < questions.length) {
//       const currentQuestion = questions[step - 1];
//       const currentCategory = currentQuestion.Category;
//       const currentSubCategory = currentQuestion.SubCategory;

//       // Handle Writing Section Remaining Timer
//       if (currentCategory === "Writing") {
//         if (currentSubCategory === "Write Essay") {
//           setShowRemainingTime(20); // Set Remaining Timer for 20 minutes for Write Essay
//         } else if (currentSubCategory === "Summarize Written Text") {
//           setShowRemainingTime(10); // Set Remaining Timer for 10 minutes for Summarize Written Text
//         } else if (currentSubCategory === "Write Email") {
//           setShowRemainingTime(9); // Set Remaining Timer for 9 minutes for Write Email
//         }
//       }

//       // Handle Listening Section Remaining Timer and Incremental Stop Watch
//       else if (currentCategory === "Listening") {
//         if (currentSubCategory === "Summarize Spoken Text") {
//           setShowRemainingTime(10); // Set RemainingTime for 10 minutes for Summarize Spoken Text
//         } else if (currentSubCategory !== "Summarize Spoken Text") {
//           if(showRemainingTime){
//             resetStopwatch(moment.duration(listeningTime, "minutes"));
//             console.log("currentCategory",currentCategory,questions[step-2].Category);
            
//             currentCategory === questions[step-2].Category && startStopwatch();
//           } 
//           setShowRemainingTime(null); // Don't show Remaining Timer for other Listening questions
//           // startStopwatch(); // Start Incremental stop watch for other Listening questions
//         }
//       }

//       // Handle Reading Section
//       else if (currentCategory === "Reading") {
//         if(showRemainingTime){
//           resetStopwatch(moment.duration(readingTime, "minutes"));
//           console.log("currentCategory",currentCategory,questions[step-2].Category);
//           currentCategory === questions[step-2].Category && startStopwatch();
//         }
//         setShowRemainingTime(null);
//         // startStopwatch();
//       }

//       // Reset Stopwatch for Other Categories
//       else {
//         console.log("its not Writing, Reading, or Listening Category");
//         setShowRemainingTime(null);
//       }
//     }
//   }, [step, questions]);

//   useEffect(() => {
//     console.log("Elapsed Time in Paren Component MockTest():", elapsedTime);
//   }, [elapsedTime]);

//   return (
//     <div>
//       <Snackbar
//         anchorOrigin={{ vertical: "top", horizontal: "right" }}
//         autoHideDuration={4000}
//         open={timeoutModel}
//         variant={"filled"}
//         color={"danger"}
//         onClose={() => setTimeoutModel(false)}
//       >
//         Time ended for this section
//       </Snackbar>
//       <Snackbar
//         anchorOrigin={{ vertical: "top", horizontal: "right" }}
//         autoHideDuration={4000}
//         open={snackbarModel}
//         variant={"filled"}
//         color={"danger"}
//         onClose={() => setsnackbarModel(false)}
//       >
//         You have already started this test !!
//       </Snackbar>
//       <Modal
//         open={open}
//         style={{
//           backgroundColor: "rgba(255, 255, 255, 0.7)",
//           backdropFilter: "blur(3px)",
//         }}
//         onClose={(event, reason) => {
//           if (reason === "backdropClick" || reason === "escapeKeyDown") {
//             return;
//           }
//           setOpen(false);
//         }}
//       >
//         <Box sx={style}>
//           <CompatibilityModal setOpen={setOpen} test={Exam} />
//         </Box>
//       </Modal>
//       {/* header */}
//       {questions.length > 0 && (
//         <div
//           style={{
//             padding: isLaptopTwo ? "15px 10px" : "5px 100px",
//             backgroundColor: "white",
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//           }}
//         >
//           <div style={{ display: "flex", gap: "10px" }}>
//             <img src={SwiftUniLogo} alt="logo" />
//             {!isMobile && <h1>Mock Test</h1>}
//           </div>

//           <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
//             <div style={{ display: "flex", gap: "10px" }}>
//               {CLOCK}
//               {showRemainingTime ? (
//                 <RemainingTime
//                   showRemainingTime={showRemainingTime}
//                   setShowRemainingTime={setShowRemainingTime}
//                   stopStopwatch={stopStopwatch}
//                   addTime={addTime}
//                   Category={questions[step - 1].Category}
//                   time={
//                     questions[step - 1].Category === "Reading"
//                       ? readingTime
//                       : questions[step - 1].Category === "Listening"
//                       ? listeningTime
//                       : speakingTime
//                   }
//                   step={step}
//                   setStep={setStep}
//                   InitStep={InitStep}
//                   questions={questions}
//                 />
//               ) : (
//                 <span>
//                   {moment.duration(elapsedTime).hours() > 0
//                     ? moment.utc(elapsedTime).format("hh:mm:ss")
//                     : moment.utc(elapsedTime).format("mm:ss")}
//                   /
//                   {questions.length > 0 &&
//                     moment
//                       .duration(
//                         questions[step - 1].Category === "Reading"
//                           ? readingTime
//                           : questions[step - 1].Category === "Listening"
//                           ? RemainingListeningTime
//                           : speakingTime,
//                         "minutes"
//                       )
//                       .minutes() + ":00"}
//                 </span>
//               )}
//             </div>
//             <div style={{ display: "flex", gap: "10px" }}>
//               {QUESTIONS}
//               <span>
//                 {step} of {questions.length}
//               </span>
//             </div>
//           </div>
//         </div>
//       )}
//       {Endloader ? (
//         <div
//           style={{
//             justifyContent: "center",
//             display: "flex",
//             alignItems: "center",
//             width: "100vw",
//             height: "80vh",
//           }}
//         >
//           <CircularProgress />
//         </div>
//       ) : questions.length > 0 ? (
//         <>
//           {questions[step - 1].Category === "Speaking" ? (
//             InitStep === 1 ? (
//               <Welcome
//                 Exam={Exam.MockTestName}
//                 InitStep={InitStep}
//                 setInitStep={setInitStep}
//                 part={1}
//                 name={"Speaking & Writing"}
//                 totalTime={totalSpeakingWritingTime}
//                 speakingTime={speakingTime} // Passing speaking time separately
//                 writingTime={writingTime}
//                 setIsTestStarted={setIsTestStarted}
//                 isResuming={!!Exam.ResumeTestId}
//               />
//             ) : (
//               <Speaking
//                 ExamId={Exam.ResumeTestId}
//                 quest={questions[step - 1]}
//                 step={step}
//                 setStep={setStep}
//                 MockTestAttemptID={MockTestAttemptID}
//                 totalQuestions={questions.length}
//                 commulative={commulative}
//                 setCommulative={setCommulative}
//               />
//             )
//           ) : questions[step - 1].Category === "Writing" ? (
//             InitStep === 444 ? ( //invalid value
//               <Welcome
//                 Exam={Exam.MockTestName}
//                 InitStep={InitStep}
//                 setInitStep={setInitStep}
//                 part={2}
//                 name={"Writing"}
//                 totalTime={writingTime}
//                 setIsTestStarted={setIsTestStarted}
//                 isResuming={!!Exam.ResumeTestId}
//               />
//             ) : (
//               <Writing
//                 ExamId={Exam.ResumeTestId}
//                 quest={questions[step - 1]}
//                 step={step}
//                 setStep={setStep}
//                 MockTestAttemptID={MockTestAttemptID}
//                 totalQuestions={questions.length}
//                 commulative={commulative}
//                 setCommulative={setCommulative}
//                 isResuming={!!Exam.ResumeTestId}
//               />
//             )
//           ) : questions[step - 1].Category === "Reading" ? (
//             InitStep === 2 ? (
//               <Welcome
//                 Exam={Exam.MockTestName}
//                 InitStep={InitStep}
//                 setInitStep={setInitStep}
//                 part={2}
//                 name={"Reading"}
//                 setCommulative={setCommulative}
//                 totalTime={readingTime}
//                 setIsTestStarted={setIsTestStarted}
//                 isResuming={!!Exam.ResumeTestId}
//               />
//             ) : (
//               <Reading
//                 ExamId={Exam.ResumeTestId}
//                 quest={questions[step - 1]}
//                 step={step}
//                 setStep={setStep}
//                 MockTestAttemptID={MockTestAttemptID}
//                 totalQuestions={questions.length}
//                 commulative={commulative}
//                 setCommulative={setCommulative}
//               />
//             )
//           ) : questions[step - 1].Category === "Listening" ? (
//             InitStep === 3 ? (
//               <Welcome
//                 Exam={Exam.MockTestName}
//                 InitStep={InitStep}
//                 setInitStep={setInitStep}
//                 part={3}
//                 name={"Listening"}
//                 totalTime={listeningTime}
//                 RemainingListeningTime={RemainingListeningTime}
//                 summarizeSpokenTextTime={summarizeSpokenTextCount * 10}
//                 setIsTestStarted={setIsTestStarted}
//                 isResuming={!!Exam.ResumeTestId}
//               />
//             ) : (
//               <Listening
//                 ExamId={Exam.ResumeTestId}
//                 quest={questions[step - 1]}
//                 step={step}
//                 setStep={setStep}
//                 MockTestAttemptID={MockTestAttemptID}
//                 totalQuestions={questions.length}
//                 commulative={commulative}
//                 setCommulative={setCommulative}
//                 setShowRemainingTime={setShowRemainingTime}
//               />
//             )
//           ) : null}
//         </>
//       ) : null}
//     </div>
//   );
// }
