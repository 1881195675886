// import { useState, useEffect, useRef } from "react";

// function useStopwatch(initialDuration) {
//   const [duration, setDuration] = useState(initialDuration || null);
//   const [elapsedTime, setElapsedTime] = useState(0);
//   const [isActive, setIsActive] = useState(false);
//   const intervalRef = useRef(null);

//   useEffect(() => {
//     if (isActive) {
//       intervalRef.current = setInterval(() => {
//         setElapsedTime((prev) => {
//           const newElapsedTime = prev + 1000;
//           if (duration && newElapsedTime >= duration) {
//             clearInterval(intervalRef.current);
//             setIsActive(false);
//             return duration; // Ensure elapsedTime doesn't exceed duration
//           }
//           return newElapsedTime;
//         });
//       }, 1000);
//     } else {
//       clearInterval(intervalRef.current);
//     }
//     return () => clearInterval(intervalRef.current);
//   }, [isActive, duration]);

//   console.log("Elapsed Time inside Stop Watch:", elapsedTime / 1000);

//   const startStopwatch = () => {
//     if (!isActive) {
//       // Only start if not already active
//       console.log("(______STARTED STOP WATCH______)");
//       setIsActive(true);
//     }
//   };

//   const stopStopwatch = () => {
//     console.log("(______STOPPED STOP WATCH______)");
//     setIsActive(false);
//   };

//   const resetElapsedTime = () => {
//     setIsActive(false);
//     setElapsedTime(0);
//   };

//   const resetStopwatch = (newDuration = null) => {
//     setIsActive(false);
//     setElapsedTime(0);
//     if (newDuration !== null) {
//       setDuration(newDuration);
//       console.log(`Stop Watch Reset for Time: ${newDuration}`);
//     }
//   };

//   const addTime = (seconds = 0) => {
//     setElapsedTime((prev) => {
//       const newElapsedTime = prev + seconds * 1000;
//       if (duration && newElapsedTime >= duration) {
//         clearInterval(intervalRef.current);
//         setIsActive(false);
//         return duration; // Ensure elapsedTime doesn't exceed duration
//       }
//       return newElapsedTime;
//     });
//   };

//   const addSSTTime = (seconds = 0) => {
//     setElapsedTime(seconds * 1000);
//     console.log(`Added ${seconds} seconds for SST Listening.`);
//   };

//   return {
//     elapsedTime,
//     isActive,
//     startStopwatch,
//     stopStopwatch,
//     resetStopwatch,
//     resetElapsedTime,
//     addTime,
//     addSSTTime,
//   };
// }

// export default useStopwatch;



import { useState, useEffect, useRef } from "react";

function useStopwatch(initialDuration) {
  const [duration, setDuration] = useState(initialDuration || null);
  const [elapsedTime, setElapsedTime] = useState(0); // Total elapsed time
  const [questionsTime, setQuestionsTime] = useState(0); // Time per question
  const [isActive, setIsActive] = useState(false);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (isActive) {
      intervalRef.current = setInterval(() => {
        setElapsedTime((prev) => {
          const newElapsedTime = prev + 1000;
          if (duration && newElapsedTime >= duration) {
            clearInterval(intervalRef.current);
            setIsActive(false);
            return duration;
          }
          return newElapsedTime;
        });
        setQuestionsTime((prev) => prev + 1000);
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [isActive, duration]);



  const startStopwatch = () => {
    setIsActive(true);
    console.log("(______STARTED STOPWATCH______)");
  };

  const stopStopwatch = () => {
    setIsActive(false);
    console.log("(______STOPPED STOPWATCH______)");
  };

  const resetElapsedTime = () => {
    clearInterval(intervalRef.current);
    setIsActive(false);
    setElapsedTime(0);
  };

  const resetQuestionTimer = () => {
    setQuestionsTime(0);
    console.log("(______RESET QUESTION TIMER______)");
  };

  const resetStopwatch = (newDuration = null) => {
    if (newDuration !== null) {
      clearInterval(intervalRef.current);
      setElapsedTime(0);
      setQuestionsTime(0);
      setIsActive(false);
      setDuration(newDuration);
    }
  };

  const addTime = (seconds = 0) => {
    const milliSeconds = seconds * 1000;
    setElapsedTime((prev) => {
      const newElapsedTime = prev + milliSeconds;
      if (duration && newElapsedTime >= duration) {
        clearInterval(intervalRef.current);
        setIsActive(false);
        return duration;
      }
      return newElapsedTime;
    });
  };

  console.log("Elapsed Time Parent:", elapsedTime / 1000);
  console.log("Questions Time:", questionsTime / 1000);

  return {
    elapsedTime,
    questionsTime,
    isActive,
    startStopwatch,
    stopStopwatch,
    resetElapsedTime,
    resetQuestionTimer,
    resetStopwatch,
    addTime,
  };
}

export default useStopwatch;

