import React, { useEffect } from "react";
import moment from "moment";
import useTimer from "../../hooks/useTimerSectional";

export default function RemainingTime({
  showRemainingTime,
  setShowRemainingTime,
  stopStopwatch,
  Category,
  addTime,
  step,
  setStep,
  questions,
  isOnWelcomePage,
}) {

  const { timeLeft, isActive, startTimer, stopTimer, resetTimer } = useTimer(showRemainingTime); 

  useEffect(() => {
    stopTimer();
    resetTimer(showRemainingTime);
    if (!isOnWelcomePage && (Category === "Writing" || (Category === "Listening" && questions[step - 1]?.SubCategory === "Summarize Spoken Text"))) {
      startTimer();
    }
  }, [isOnWelcomePage, step, showRemainingTime, Category]);

  useEffect(() => {
    if (timeLeft === 0) {
      // const timeUsed = showRemainingTime;
      // const totalAllocatedTime = moment.duration(showRemainingTime, "minutes").asSeconds();
      // addTime(moment.duration(timeUsed, "minutes").asSeconds());
      stopTimer();
      setShowRemainingTime(null);
      setStep((prev) => prev + 1);
    }
  }, [timeLeft]);

  // console.log("timeLeft in Remaining Time Sectional:", timeLeft / 1000);

  return <div>Remaining Time : {moment.utc(timeLeft).format("mm:ss")}</div>;
}
