import React, { useEffect, useState, useRef } from "react";
import TestHeading from "../components/Common/TestHeading";
import LWFDLogo from "../assets/images/L_WFD_Logo.svg";
import { L_WFD_Subheading } from "../components/Common/Data";
import Navbar from "../components/Navbar/Navbar";
import ButtonList from "../components/Common/ButtonList";
import CommunityScore from "../components/Common/CommunityScore";
import { SWT_QCard_Div } from "./Style";
import AudioPlayer from "../components/Speaking/AudioPlayer";
import { FlexDiv } from "../assets/styles/style";
import TextAreaInput from "../components/Writing/TextAreaInput";
import SidePannel from "../components/Common/SidePannel";
import { useAuth } from "../authentication/Auth";
import { useNavigate } from "react-router-dom";
import ReadingAnswerBox from "../components/Common/ReadingAnswerBox";
import { AiScorePopupListeningWFDData } from "../components/Listening/data";
import AiScorePopupListeningWFD from "../components/Listening/AiScorePopupListeningWFD";
import Modal from "react-modal";
import ShowScriptBox from "../components/Common/ShowScriptBox";
import { SidePannelBackdrop } from "../components/Common/Style";
import LoadingModal from "../components/Common/LoadingModal";
import { Base_URL, Base_URL_AiScore } from "../Client/apiURL";

const questionname = "Write from Dictation";

const ListeningWFD = () => {
  const [testQuestions, setTestQuestions] = useState([]);
  const [questionsData, setQuestionsData] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [questionId, setQuestionID] = useState();
  const [scorecardOpen, setScorecardOpen] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [triggerReset, setTriggerReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [enableSkillsData, setEnableSkillsData] = useState([]);
  const [scoreDataSubmitted, setScoreDataSubmitted] = useState(false);
  const [testQuestionTableId, setTestQuestionTableId] = useState();
  const [myAttemptedQuestionsScore, setMyAttemptedQuestionsScore] =
    useState(null);
  const [marksObtained, setMarksObtained] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState([""]);
  const [shouldOpenScorecard, setShouldOpenScorecard] = useState(false);
  const [isScoreDataReadyForSubmit, setIsScoreDataReadyForSubmit] =
    useState(false);
  const [lastScoreUpdate, setLastScoreUpdate] = useState(Date.now());
  const [searchTerm, setSearchTerm] = useState("");
  const [wfdScore, setWfdScore] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [filterBookmarked, setFilterBookmarked] = useState(false);
  const [filterPrediction, setFilterPrediction] = useState(false);
  const [wantToSortDesc, setWantToSortDesc] = useState(false);
  const [highFrequency, setHighFrequency] = useState(false);
  const [isPracticed, setIsPracticed] = useState("all");
  const [isEasy, setIsEasy] = useState(null);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmarkId, setBookmarkId] = useState(null);
  const [testAttemptedCount, setTestAttemptedCount] = useState(null);
  const [wordCount, setWordCount] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isAudioPlayerDisabled, setIsAudioPlayerDisabled] = useState(false);
  const audioRef = useRef(null);

  const handleSetLoading = (loadingState) => {
    setIsDataLoading(loadingState);
  };

  useEffect(() => {
    if (questionsData) {
      setIsDataLoading(false);
    }
  }, [questionsData]);

  const updateLocalQuestionBookmark = (
    testQuestionTableId,
    newBookmarkStatus,
    bookmarkId
  ) => {
    setTestQuestions((prevQuestions) => ({
      ...prevQuestions,
      response: prevQuestions.response.map((question) =>
        question.TestQuestionTableId === testQuestionTableId
          ? {
              ...question,
              IsBookMarked: newBookmarkStatus,
              BookMarkedId: newBookmarkStatus ? bookmarkId : null,
            }
          : question
      ),
    }));
  };

  useEffect(() => {
    const question = testQuestions?.response?.find(
      (q) => q.QuestionId === questionId
    );
    if (question) {
      setIsBookmarked(question.IsBookMarked);
      setBookmarkId(question.BookMarkedId);
      setTestAttemptedCount(question.TestAttemptedCount);
    }
  }, [testQuestions, testQuestionTableId]);

  const handleBookmarkChange = (newIsBookmarked, newBookmarkId) => {
    setIsBookmarked(newIsBookmarked);
    setBookmarkId(newBookmarkId);
  };

  useEffect(() => {
    if (
      testQuestions.response &&
      testQuestions.response.length > 0 &&
      currentQuestionIndex < testQuestions.response.length
    ) {
      const question = testQuestions.response[currentQuestionIndex];
      setQuestionID(question.QuestionId);
      setTestQuestionTableId(question.TestQuestionTableId);
    }
  }, [testQuestions, currentQuestionIndex]);

  const handleNextQuestion = () => {
    // console.log("Current Index:", currentQuestionIndex);
    // console.log("Total questions on this page:", testQuestions.response?.length);
    // console.log("Current page:", page);
    // console.log("Total pages:", totalPages);

    if (
      currentQuestionIndex <
      (testQuestions.response ? testQuestions.response.length - 1 : 0)
    ) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else if (page < totalPages) {
      setPage(page + 1);
      setCurrentQuestionIndex(0);
    } else {
      console.log("No more questions to display.");
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    } else if (page > 1) {
      setPage(page - 1);
      setCurrentQuestionIndex(testQuestions.response.length - 1);
    } else {
      console.log("No previous questions to display.");
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleMyAttemptedQuestionsScore = (score, openScorecard) => {
    setMyAttemptedQuestionsScore(score);
    if (openScorecard) {
      setShouldOpenScorecard(true);
    }
  };

  useEffect(() => {
    if (shouldOpenScorecard) {
      setScorecardOpen(true);
      setShouldOpenScorecard(false);
    }
  }, [shouldOpenScorecard]);

  function canSubmit() {
    false;
  }

  function getQuestionName() {
    const questionTemp = testQuestions?.response?.find(
      (q) => q.QuestionId === questionId
    );
    if (questionTemp) {
      return questionTemp.QuestionName;
    } else {
      return "loading";
    }
  }

  const handleSearchChange = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
  };

  useEffect(() => {
    setPage(1);
  }, [filterBookmarked, filterPrediction]);

  useEffect(() => {
    const fetchData = async () => {
      const encodedQuestionName = encodeURIComponent(questionname);
      const searchQueryParam = searchTerm
        ? `&search_name=${encodeURIComponent(searchTerm)}`
        : "";
      const highFrequencyParam = highFrequency ? `&high_frequency=true` : "";
      let sortDescParam = highFrequency
        ? ""
        : `&order_by=${wantToSortDesc ? "asc" : "desc"}`;
      let isPracticedParam =
        isPracticed === "all" ? "" : `&is_practiced=${isPracticed}`;
      let isPredictionParam = filterPrediction
        ? `&prediction=${filterPrediction}`
        : `&prediction=${false}`;
      let isEasyParam =
        isEasy === true || isEasy === false ? `&easy=${isEasy}` : "";
      let isPteCoreParam = false;

      const queryParams = `test_name=${encodedQuestionName}&page=${page}&bookmarked=${filterBookmarked}&is_ptecore=${isPteCoreParam}${sortDescParam}${highFrequencyParam}${isPracticedParam}${searchQueryParam}${isPredictionParam}${isEasyParam}`;

      try {
        const url = `${Base_URL}/app/users/test-questions/get-by-name?${queryParams}`;
        const response = await fetch(url, { credentials: "include" });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.responseCode === 501) {
          logout();
          navigate("/login");
          return;
        }
        setTestQuestions(data);
        setQuestionID(data.response[0].QuestionId);
        setTestQuestionTableId(data.response[0].TestQuestionTableId);
        const totalQuestions = data.totalQuestions || 1;
        const questionsPerPage = 8;
        const calculatedTotalPages = Math.ceil(
          totalQuestions / questionsPerPage
        );
        setTotalPages(calculatedTotalPages);
      } catch (error) {
        console.error("Failed to fetch test questions:", error);
      }
    };
    fetchData();
  }, [
    page,
    searchTerm,
    filterBookmarked,
    filterPrediction,
    isPracticed,
    isEasy,
    wantToSortDesc,
    highFrequency,
  ]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const scorecardElement = document.getElementById("scorecard");
      if (scorecardElement && !scorecardElement.contains(event.target)) {
        setScorecardOpen(false);
        // setSelectedAnswers("");
        setElapsedTime(0);
        setEnableSkillsData([]);
        setMyAttemptedQuestionsScore(null);
        setMarksObtained(0);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleSubmission = async () => {
    if (
      selectedAnswers &&
      typeof selectedAnswers === "string" &&
      selectedAnswers.trim() !== "" &&
      questionsData
    ) {
      setIsAudioPlayerDisabled(true);
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setIsLoading(true);
      try {
        const correctAnswer =
          typeof questionsData.response.AnswerNames === "string"
            ? questionsData.response.AnswerNames
            : questionsData.response.AnswerNames.join(" ");
        setCorrectAnswers(correctAnswer);
        const payload = {
          correct_answer: correctAnswer,
          user_response: selectedAnswers,
        };

        const response = await fetch(`${Base_URL_AiScore}/write_dictation`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
        const data = await response.json();
        if (data) {
          setWfdScore(data);
          setIsSubmitted(true);
          setScorecardOpen(true);
          setIsScoreDataReadyForSubmit(true);
        } else {
          logout();
          navigate("/login");
        }
      } catch (error) {
        console.error("Failed to fetch essay score:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    async function submitScoreData() {
      const userResponse = {
        correctAnswers: questionsData?.response?.AnswerNames,
        selectedAnswers: selectedAnswers,
        enableSkillsData: wfdScore,
      };

      const payload = {
        test_question_id: testQuestionTableId,
        marks_obtained: wfdScore.writing_score,
        user_response: JSON.stringify(userResponse),
        time_taken: elapsedTime,
        is_ptecore: false,
      };

      try {
        const response = await fetch(
          `${Base_URL}/app/users/attempted-questions/add`,
          {
            credentials: "include",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        const data = await response.json();
        if (data.responseCode === 200) {
          console.log(data.message);
          setScoreDataSubmitted(true);
          setIsScoreDataReadyForSubmit(false);
          setLastScoreUpdate(Date.now());
        } else {
          console.log(data.message);
        }
      } catch (error) {
        console.error("Failed to submit score data:", error);
      }
    }

    const shouldSubmitScore =
      isScoreDataReadyForSubmit && !scoreDataSubmitted && elapsedTime;
    if (shouldSubmitScore) {
      submitScoreData();
    }
  }, [isScoreDataReadyForSubmit, scoreDataSubmitted, elapsedTime]);

  const resetState = () => {
    setSelectedAnswers("");
    setShowAnswer(false);
    setScorecardOpen(false);
    setIsSubmitted(false);
    setElapsedTime(0);
    setTriggerReset((prevState) => !prevState);
    setEnableSkillsData([]);
    setScoreDataSubmitted(false);
    setMyAttemptedQuestionsScore(null);
    setMarksObtained(0);
    setShouldOpenScorecard(false);
    setIsAudioPlayerDisabled(false);
  };

  useEffect(() => {
    resetState();
  }, [questionId]);

  const handleRedo = () => {
    resetState();
  };

  useEffect(() => {
    const words = questionsData?.response?.AnswerNames.join(" ")
      .trim()
      .split(/\s+/);
    if (questionsData?.response?.AnswerNames !== undefined) {
      setWordCount(words.length);
    }
  }, [questionsData?.response?.AnswerNames]);

  const calculateDaysDifference = (createdAt) => {
    const currentDate = new Date();
    const createdDate = new Date(createdAt);
    const timeDiff = currentDate - createdDate;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff < 60;
  };

  return (
    <>
      {isDataLoading && <LoadingModal />}
      <div id="scorecardd">
        {scorecardOpen && (
          <FlexDiv
            style={{
              position: "fixed",
              top: " 50%",
              left: " 50%",
              transform: "translate(-50%, -50%)",
              zIndex: "1002",
              backdropFilter: "blur(5px)",
              WebkitBackdropFilter: "blur(5px)",
              width: "100vw",
              height: "100vh",
            }}
          >
            <AiScorePopupListeningWFD
              key={questionId}
              wfdScore={
                myAttemptedQuestionsScore
                  ? JSON.parse(myAttemptedQuestionsScore.UsersResponse)
                      .enableSkillsData
                  : wfdScore
              }
              SmallScoreCard={AiScorePopupListeningWFDData[0].SmallScoreCard}
              correctAnswer={questionsData?.response?.AnswerNames}
              // correctAnswer={
              //   myAttemptedQuestionsScore
              //     ? JSON.parse(myAttemptedQuestionsScore.UsersResponse)
              //         .correctAnswers
              //     : questionsData?.response?.AnswerNames
              // }
              UserResponse={
                myAttemptedQuestionsScore
                  ? JSON.parse(myAttemptedQuestionsScore.UsersResponse)
                      .selectedAnswers
                  : selectedAnswers
              }
              elapsedTime={
                myAttemptedQuestionsScore
                  ? myAttemptedQuestionsScore.TimeTaken
                  : elapsedTime
              }
              close={setScorecardOpen}
              isOpen={scorecardOpen}
            />
          </FlexDiv>
        )}
      </div>
      {isSidePanelOpen && <SidePannelBackdrop isOpen={isSidePanelOpen} />}
      <SidePannel
        onToggle={setIsSidePanelOpen}
        testQuestions={testQuestions.response || []}
        totalTestQuestions={testQuestions.totalQuestions}
        heading={"Write from Dictation"}
        setQuestionsData={setQuestionsData}
        questionID={questionId}
        setQuestionID={setQuestionID}
        setSelectedAnswers={setSelectedAnswers}
        setTriggerReset={setTriggerReset}
        logo={LWFDLogo}
        setTestQuestionTableId={setTestQuestionTableId}
        testQuestionTableId={testQuestionTableId}
        handleSearchChange={handleSearchChange}
        onPageChange={handlePageChange}
        currentPage={page}
        totalPages={totalPages}
        updateLocalQuestionBookmark={updateLocalQuestionBookmark}
        setFilterBookmarked={setFilterBookmarked}
        setFilterPrediction={setFilterPrediction}
        setWantToSortDesc={setWantToSortDesc}
        setHighFrequency={setHighFrequency}
        setIsPracticed={setIsPracticed}
        setIsEasy={setIsEasy}
        setDataLoading={handleSetLoading}
        setCurrentQuestionIndex={setCurrentQuestionIndex}
      />
      <Navbar />
      <FlexDiv style={{ width: "100%" }}>
        <FlexDiv
          style={{ flexDirection: "column", width: "100%", maxWidth: "1880px" }}
        >
          <TestHeading
            logo={LWFDLogo}
            heading={"Write From Dictation"}
            subheading={L_WFD_Subheading}
            serialNo={questionId ? "#" + questionId : "#000"}
            questionName={getQuestionName()}
            isSubmitted={isSubmitted}
            setElapsedTime={setElapsedTime}
            triggerReset={triggerReset}
            testQuestionTableId={testQuestionTableId}
            appearedCount={questionsData?.response?.AppearedCount}
            IsBookMarked={isBookmarked}
            BookMarkedId={bookmarkId}
            onBookmarkChange={handleBookmarkChange}
            questionID={questionId}
            dictionaryText={
              questionsData?.response?.AnswerNames &&
              Array.isArray(questionsData.response.AnswerNames)
                ? questionsData.response.AnswerNames.join(",")
                : ""
            }
            testAttemptedCount={testAttemptedCount}
            isPrediction={questionsData?.response?.Prediction}
            isNew={calculateDaysDifference(questionsData?.response?.CreatedAt)}
          />

          {questionsData && questionsData.response && (
            <>
              <SWT_QCard_Div>
                <AudioPlayer
                  AudioObjects={questionsData.response.AudioObjects}
                  resetTrigger={triggerReset}
                  isAudioPlayerDisabled={isAudioPlayerDisabled}
                  audioRef={audioRef}
                />
              </SWT_QCard_Div>
              <SWT_QCard_Div>
                <TextAreaInput
                  key={`Text-area-input-${triggerReset}`}
                  placeholder={"Type your response here..."}
                  renderWC={false}
                  height={"200px"}
                  setSelectedAnswers={setSelectedAnswers}
                  isDisabled={isSubmitted}
                />
              </SWT_QCard_Div>
            </>
          )}
          <ButtonList
            onSubmit={() => {
              handleSubmission();
            }}
            onRedo={() => handleRedo()}
            canSubmit={canSubmit}
            isLoading={isLoading}
            onAnswer={() => setShowAnswer(!showAnswer)}
            renderAnswer={true}
            renderScript={false}
            isSubmitted={isSubmitted}
            onNext={handleNextQuestion}
            onPrevious={handlePreviousQuestion}
            hasPrevious={currentQuestionIndex > 0 || page > 1}
            hasNext={
              currentQuestionIndex <
                (testQuestions.response
                  ? testQuestions.response.length - 1
                  : 0) || page < totalPages
            }
            handleSearchChange={handleSearchChange}
          />
          {showAnswer && questionsData?.response?.AnswerNames && (
            <ReadingAnswerBox
              answerText={questionsData?.response?.AnswerNames}
              addIndex={false}
              color="#16161e)"
            />
          )}
          {/* {showAnswer && questionsData?.response?.QuestionStatement && (
            <ShowScriptBox
              answerText={questionsData?.response?.QuestionStatement}
            />
          )} */}

          <CommunityScore
            ScoreLetter="L"
            bg="#868EAF"
            totalScore={wordCount}
            // totalScore={wfdScore.total_score}
            WritingTotalScore="2"
            testQuestionTableId={testQuestionTableId}
            onSelectMyScore={handleMyAttemptedQuestionsScore}
            lastScoreUpdate={lastScoreUpdate}
            testSubmissionInProcess={isLoading}
          />
        </FlexDiv>
      </FlexDiv>
    </>
  );
};

export default ListeningWFD;
