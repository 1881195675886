// StopwatchContext.js (modified)
import React, { createContext, useContext, useMemo } from 'react';
import useStopwatch from './useStopwatch';

const StopwatchContext = createContext();

export const StopwatchProvider = ({ initialDuration = null, children }) => {
  const stopwatch = useStopwatch(initialDuration);

  const value = useMemo(() => stopwatch, [stopwatch.elapsedTime, stopwatch.isActive, stopwatch.duration]);

  return (
    <StopwatchContext.Provider value={value}>
      {children}
    </StopwatchContext.Provider>
  );
};

export const useStopwatchContext = () => {
  const context = useContext(StopwatchContext);
  if (!context) {
    throw new Error('useStopwatchContext must be used within a StopwatchProvider');
  }
  return context;
};
