import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { FlexDiv } from "../../assets/styles/style";
import {
  AnalyticsHeader,
  FeedbackHeaderTitle,
  WhiteDiv,
  CircleDiv,
  CircleScoresDiv,
  PointImg,
  ScoreDigit,
  PointAndScore,
  ImgAndLine,
} from "./style";
import ReadingImg from "../../assets/todaytaskcard/todaytaskimg1.svg";
import ListeningImg from "../../assets/todaytaskcard/todaytaskimg2.svg";
import WritingImg from "../../assets/todaytaskcard/todaytaskimg3.svg";
import SpeakingImg from "../../assets/todaytaskcard/todaytaskimg4.svg";
import DividerLine from "../../assets/images/dividersvg.svg";
import ReadingPoint from "../../assets/images/readingpoint.svg";
import WritingPoint from "../../assets/images/writingpoint.svg";
import ListeningPoint from "../../assets/images/listeningpoint.svg";
import SpeakingPoint from "../../assets/images/speakingpoint.svg";
import AnalyticsTestCard from "./AnalyticsTestCard";
import AnalyticsCircle from "./AnalyticsCircle";
import AnalyticsForMtScore from "./AnalyticsForMtScore";
import AnalyticsTestCard2 from "./AnalyticsTestCard2";
import axios from "axios";
import { useParams } from "react-router-dom";
import LoadingModal from "../Common/LoadingModal";
import { PieChart } from "react-minimal-pie-chart";
import { Base_URL } from "../../Client/apiURL";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

function convertSecondsToMMSS(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return isNaN(formattedSeconds)
    ? "00:00"
    : `${formattedMinutes}:${formattedSeconds}`;
}

const SpeakingQuestionNames = [
  "Read Aloud",
  "Repeat Sentence",
  "Describe Image",
  "Re-tell Lecture",
  "Answer Short Question",
];

const WritingQuestionNames = ["Summarize Written Text", "Write Essay"];

const ReadingQuestionNames = [
  "Reading and Writing: Fill in the Blanks",
  "Multiple Choice, Multiple Answers",
  "Re-order Paragraphs",
  "Reading: Fill in the Blanks",
  "Multiple Choice, Single Answer",
];

const ListeningQuestionNames = [
  "Summarize Spoken Text",
  "Listening: Multiple Choice, Multiple Answers",
  "Listening: Fill in the Blanks",
  "Highlight Correct Summary",
  "Listening: Multiple Choice, Single Answer",
  "Select Missing Word",
  "Highlight Incorrect Words",
  "Write from Dictation",
];

const ListenQuestionNames = [
  "Summarize Spoken Text",
  "Multiple Choice, Multiple Answers",
  "Fill in the Blanks",
  "Highlight Correct Summary",
  "Multiple Choice, Single Answer",
  "Select Missing Word",
  "Highlight Incorrect Words",
  "Write from Dictation",
];

const Feedback = () => {
  const isTab = useMediaQuery("(max-width:1000px)");
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const { id } = useParams();
  const [mockTestsResult, setMockTestsResult] = useState(null);
  const [listeningTests, setListeningTests] = useState([]);
  const [readingTests, setReadingTests] = useState([]);
  const [writingTests, setWritingTests] = useState([]);
  const [speakingTests, setSpeakingTests] = useState([]);
  const [testCounts, setTestCounts] = useState(null);
  const [speakingScore, setSpeakingScore] = useState(0);
  const [writingsScore, setWritingScore] = useState(0);
  const [readingsScore, setReadingScore] = useState(0);
  const [listeningsScore, setListeningScore] = useState(0);
  const [testTimimgs, setTestTimings] = useState(null);


  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${Base_URL}/app/users/mock-test-attempts/get-score?mock_test_attempted_id=${id}`,
        { withCredentials: true }
      );

      if (response.status === 200 && response.data.message === "Mock Test Score:") {
        const parsedResponses = response.data.response.map((item) => {
          let userResponse;
          try {
            userResponse = item.UserResponse ? 
              (typeof JSON.parse(item.UserResponse) === "string" 
                ? JSON.parse(JSON.parse(item.UserResponse)) 
                : JSON.parse(item.UserResponse)) 
              : null;
          } catch (e) {
            console.error("Error parsing UserResponse:", e);
            userResponse = null;
          }

          return {
            ...item,
            UserResponse: userResponse,
          };
        });

        // Check if all UserResponses are null
        const hasScore = parsedResponses.some(item => item.UserResponse !== null);

        if (hasScore) {
          setMockTestsResult(parsedResponses);
          splitCategories(parsedResponses);
        } else {
          navigate('/mt-score'); 
          toast.error("No score available");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch data");
    }
  };

  const splitCategories = (data) => {
    const categorizedData = data.reduce(
      (acc, curr) => {
        switch (curr.UserResponse.SubCategory) {
          case "Write Essay":
            acc.writing.push(curr);
            acc.we.push(curr);
            break;
          case "Summarize Written Text":
            acc.writing.push(curr);
            acc.swt.push(curr);
            acc.reading.push(curr);
            break;
          case "Reading & Writing: Fill in the Blanks":
            acc.writing.push(curr);
            acc.reading.push(curr);
            acc.rwfib.push(curr);
            break;
          case "Summarize Spoken Text":
            acc.writing.push(curr);
            acc.listening.push(curr);
            acc.sst.push(curr);
            break;
          case "Fill in the Blanks":
            acc.writing.push(curr);
            acc.listening.push(curr);
            acc.lfib.push(curr);
            break;
          case "Write from Dictation":
            acc.writing.push(curr);
            acc.listening.push(curr);
            acc.wfd.push(curr);
            break;
          case "Read Aloud":
            acc.reading.push(curr);
            acc.speaking.push(curr);
            acc.ra.push(curr);
            break;
          case "Multiple Choice, Multiple Answers":
            acc.reading.push(curr);
            acc.mcma.push(curr);
            break;
          case "Re-order Paragraphs":
            acc.reading.push(curr);
            acc.rop.push(curr);
            break;
          case "Multiple Choice, Single Answer":
            acc.reading.push(curr);
            acc.mcsa.push(curr);
            break;
          case "Highlight Correct Summary":
            acc.reading.push(curr);
            acc.listening.push(curr);
            acc.hcs.push(curr);
            break;
          case "Highlight Incorrect Words":
            acc.reading.push(curr);
            acc.listening.push(curr);
            acc.hiw.push(curr);
            break;
          case "Reading: Fill in the Blanks":
            acc.reading.push(curr);
            acc.rfib.push(curr);
            break;
          case "Repeat Sentence":
            acc.speaking.push(curr);
            acc.listening.push(curr);
            acc.rs.push(curr);
            break;
          case "Describe Image":
            acc.speaking.push(curr);
            acc.di.push(curr);
            break;
          case "Re-tell Lecture":
            acc.speaking.push(curr);
            acc.listening.push(curr);
            acc.rl.push(curr);
            break;
          case "Answer Short Question":
            acc.speaking.push(curr);
            acc.listening.push(curr);
            acc.asq.push(curr);
            break;
          default:
            acc.listening.push(curr);
            switch (curr.UserResponse.SubCategory) {
              case "Listening: Multiple Choice, Multiple Answers":
                acc.lmcma.push(curr);
                break;
              case "Listening: Multiple Choice, Single Answer":
                acc.lmcsa.push(curr);
                break;
              default:
                acc.smw.push(curr);
                break;
            }
            break;
        }
        return acc;
      },
      {
        listening: [],
        reading: [],
        writing: [],
        speaking: [],
        ra: [],
        rs: [],
        di: [],
        rl: [],
        asq: [],
        swt: [],
        we: [],
        rwfib: [],
        rfib: [],
        mcma: [],
        mcsa: [],
        rop: [],
        sst: [],
        lmcma: [],
        lmcsa: [],
        hiw: [],
        lfib: [],
        wfd: [],
        hcs: [],
        smw: [],
      }
    );

    setTestCounts([
      categorizedData.ra,
      categorizedData.rs,
      categorizedData.di,
      categorizedData.rl,
      categorizedData.asq,
      categorizedData.swt,
      categorizedData.we,
      categorizedData.rwfib,
      categorizedData.rfib,
      categorizedData.mcma,
      categorizedData.mcsa,
      categorizedData.rop,
      categorizedData.sst,
      categorizedData.lmcma,
      categorizedData.lmcsa,
      categorizedData.hiw,
      categorizedData.lfib,
      categorizedData.wfd,
      categorizedData.hcs,
      categorizedData.smw,
    ]);
    setTestTimings({
      "Read Aloud": categorizedData.ra,
      "Repeat Sentence": categorizedData.rs,
      "Describe Image": categorizedData.di,
      "Re-tell Lecture": categorizedData.rl,
      "Answer Short Question": categorizedData.asq,
      "Summarize Written Text": categorizedData.swt,
      "Write Essay": categorizedData.we,
      "Reading and Writing: Fill in the Blanks": categorizedData.rwfib,
      "Reading: Fill in the Blanks": categorizedData.rfib,
      "Multiple Choice, Multiple Answers": categorizedData.mcma,
      "Multiple Choice, Single Answer": categorizedData.mcsa,
      "Re-order Paragraphs": categorizedData.rop,
      "Summarize Spoken Text": categorizedData.sst,
      "Listening: Multiple Choice, Multiple Answers": categorizedData.lmcma,
      "Listening: Multiple Choice, Single Answer": categorizedData.lmcsa,
      "Highlight Incorrect Words": categorizedData.hiw,
      "Listening: Fill in the Blanks": categorizedData.lfib,
      "Write from Dictation": categorizedData.wfd,
      "Highlight Correct Summary": categorizedData.hcs,
      "Select Missing Word": categorizedData.smw,
    });
    setListeningTests(categorizedData.listening);
    setReadingTests(categorizedData.reading);
    setWritingTests(categorizedData.writing);
    setSpeakingTests(categorizedData.speaking);
  };

  const getTimeTaken = (testName = "Read Aloud", mmss = true) => {
    if (testTimimgs && testTimimgs[testName]) {
      const time = testTimimgs[testName].reduce(
        (acc, test) => acc + test.TimeTaken,
        0
      );
      return Number.isInteger(mmss) ? convertSecondsToMMSS(time) : time;
    } else {
      return "00:00";
    }
  };

  const getSpeakingTimeTaken = () => SpeakingQuestionNames.map(getTimeTaken);
  const getTotalSpeakingTimeTaken = () => {
    let tot = 0;
    SpeakingQuestionNames.forEach((test) => {
      tot += getTimeTaken(test, false);
    });
    return convertSecondsToMMSS(tot);
  };
  const getWritingTimeTaken = () => WritingQuestionNames.map(getTimeTaken);
  const getTotalWritingTimeTaken = () => {
    let tot = 0;
    WritingQuestionNames.forEach((test) => {
      tot += getTimeTaken(test, false);
    });
    return convertSecondsToMMSS(tot);
  };
  const getReadingTimeTaken = () => ReadingQuestionNames.map(getTimeTaken);
  const getTotalReadingTimeTaken = () => {
    let tot = 0;
    ReadingQuestionNames.forEach((test) => {
      tot += getTimeTaken(test, false);
    });
    return convertSecondsToMMSS(tot);
  };
  const getListeningTimeTaken = () => ListeningQuestionNames.map(getTimeTaken);
  const getTotalListeningTimeTaken = () => {
    let tot = 0;
    ListeningQuestionNames.forEach((test) => {
      tot += getTimeTaken(test, false);
    });
    return convertSecondsToMMSS(tot);
  };

  const getTotalTimeTaken = () => {
    let tot = 0;

    [
      ListeningQuestionNames,
      ReadingQuestionNames,
      WritingQuestionNames,
      SpeakingQuestionNames,
    ].forEach((questionNames) => {
      questionNames.forEach((test) => {
        tot += getTimeTaken(test, false);
      });
    });

    return convertSecondsToMMSS(tot);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return mockTestsResult && mockTestsResult[0]?.MockTestName ? (
    <>
      <FlexDiv
        style={{
          flexDirection: "column",
          padding: isTab ? "1.5rem 2% 2rem" : "6.5rem 3% 2rem",
        }}
      >
        <AnalyticsHeader>
          <FeedbackHeaderTitle>
            ANALYTICS: <span>{mockTestsResult[0]?.MockTestName}</span>
          </FeedbackHeaderTitle>
        </AnalyticsHeader>
        <WhiteDiv>
          <FlexDiv
            style={{
              padding: "2.5rem 0rem 0rem ",
              width: "96%",
              flexDirection: isTab ? "column" : "row",
              gap: "1.52rem",
            }}
          >
            <FlexDiv
              style={{
                justifyContent: isTab ? "center" : "flex-end",
                width: "50%",
              }}
            >
              <CircleDiv>
                {/* <AnalyticsCircle /> */}
                <PieChart
                  label={({ dataEntry }) => dataEntry.title}
                  labelStyle={{
                    fill: "white",
                    fontSize: "6px",
                    fontFamily: "Helvetica Neue,sans-serif",
                    textShadow: "1px 1px 5px #000",
                  }}
                  labelPosition={65}
                  lineWidth={58}
                  data={[
                    {
                      title: "Reading",
                      value: readingsScore ? readingsScore : 0,
                      color: "#AD826E",
                    },
                    {
                      title: "Listening",
                      value: listeningsScore ? listeningsScore : 0,
                      color: "#868EAF",
                    },
                    {
                      title: "Speaking",
                      value: speakingScore ? speakingScore : 0,
                      color: "#49D7F2",
                    },
                    {
                      title: "Writing",
                      value: writingsScore ? writingsScore : 0,
                      color: "#FF5D5D",
                    },
                  ]}
                />
                <ImgAndLine>
                  <img
                    src={DividerLine}
                    alt=""
                    style={{ width: isMobile ? "" : "100%" }}
                  />
                  <CircleScoresDiv>
                    <PointAndScore>
                      <PointImg src={SpeakingPoint} alt="" />
                      <ScoreDigit color={"#49D7F2"}>
                        {speakingScore ? speakingScore : 0}
                      </ScoreDigit>
                    </PointAndScore>
                    <PointAndScore>
                      <PointImg src={WritingPoint} alt="" />
                      <ScoreDigit color={"#FF5D5D"}>
                        {writingsScore ? writingsScore : 0}
                      </ScoreDigit>
                    </PointAndScore>
                    <PointAndScore>
                      <PointImg src={ReadingPoint} alt="" />
                      <ScoreDigit color={"#AD826E"}>
                        {readingsScore ? readingsScore : 0}
                      </ScoreDigit>
                    </PointAndScore>
                    <PointAndScore>
                      <PointImg src={ListeningPoint} alt="" />
                      <ScoreDigit color={"#868EAF"}>
                        {listeningsScore ? listeningsScore : 0}
                      </ScoreDigit>
                    </PointAndScore>
                  </CircleScoresDiv>
                </ImgAndLine>
              </CircleDiv>
            </FlexDiv>
            <FlexDiv
              style={{ flexDirection: "column", gap: "1.25rem", width: "95%" }}
            >
              <FlexDiv
                style={{
                  flexDirection: isMobile ? "column" : "row",
                  gap: "1.25rem",
                  width: "100%",
                  justifyContent: isTab ? "center" : "flex-start",
                }}
              >
                <AnalyticsTestCard
                  headerName="Speaking"
                  imgSrc={SpeakingImg}
                  color="#49D7F2"
                  score={speakingScore ? speakingScore : 0}
                  questions={`${speakingTests.length}/${speakingTests.length}`}
                />
                <AnalyticsTestCard
                  headerName="Writing"
                  imgSrc={WritingImg}
                  color="#FF5D5D"
                  score={writingsScore ? writingsScore : 0}
                  questions={`${writingTests.length}/${writingTests.length}`}
                />
              </FlexDiv>

              <FlexDiv
                style={{
                  flexDirection: isMobile ? "column" : "row",
                  gap: "1.25rem",
                  width: "100%",
                  justifyContent: isTab ? "center" : "flex-start",
                }}
              >
                <AnalyticsTestCard
                  headerName="Reading"
                  imgSrc={ReadingImg}
                  color="#AD826E"
                  score={readingsScore ? readingsScore : 0}
                  questions={`${readingTests.length}/${readingTests.length}`}
                />
                <AnalyticsTestCard
                  headerName="Listening"
                  imgSrc={ListeningImg}
                  color="#868EAF"
                  score={listeningsScore ? listeningsScore : 0}
                  questions={`${listeningTests.length}/${listeningTests.length}`}
                />
              </FlexDiv>
            </FlexDiv>
          </FlexDiv>
          <FlexDiv style={{ width: isMobile ? "95%" : "100%" }}>
            <AnalyticsForMtScore
              counts={testCounts}
              speakingTotScore={setSpeakingScore}
              writingTotScore={setWritingScore}
              readingTotScore={setReadingScore}
              listeningTotScore={setListeningScore}
            />
          </FlexDiv>
        </WhiteDiv>
      </FlexDiv>
      <FlexDiv
        style={{
          flexDirection: "column",
          padding: isTab ? "0rem 2% 2rem" : "0rem 3% 2rem",
        }}
      >
        <AnalyticsHeader>
          <FeedbackHeaderTitle>
            TIME TAKEN: <span>{getTotalTimeTaken()}</span>{" "}
          </FeedbackHeaderTitle>
        </AnalyticsHeader>
        <WhiteDiv>
          <FlexDiv
            style={{
              flexDirection: "column",
              gap: "1.25rem",
              width: "92%",
              padding: "2.5rem 0rem",
            }}
          >
            <FlexDiv
              style={{
                flexDirection: isMobile ? "column" : "row",
                gap: "1.25rem",
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <AnalyticsTestCard2
                headerName="Speaking"
                imgSrc={SpeakingImg}
                color="#49D7F2"
                QuestinsTotalTime={getTotalSpeakingTimeTaken()}
                QuestionNames={SpeakingQuestionNames}
                QuestionTimes={getSpeakingTimeTaken()}
              />
              <AnalyticsTestCard2
                headerName="Writing"
                imgSrc={WritingImg}
                color="#FF5D5D"
                QuestinsTotalTime={getTotalWritingTimeTaken()}
                QuestionNames={WritingQuestionNames}
                QuestionTimes={getWritingTimeTaken()}
              />
            </FlexDiv>

            <FlexDiv
              style={{
                flexDirection: isMobile ? "column" : "row",
                gap: "1.25rem",
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <AnalyticsTestCard2
                headerName="Reading"
                imgSrc={ReadingImg}
                color="#AD826E"
                QuestinsTotalTime={getTotalReadingTimeTaken()}
                QuestionNames={ReadingQuestionNames}
                QuestionTimes={getReadingTimeTaken()}
              />
              <AnalyticsTestCard2
                headerName="Listening"
                imgSrc={ListeningImg}
                color="#868EAF"
                QuestinsTotalTime={getTotalListeningTimeTaken()}
                QuestionNames={ListenQuestionNames}
                QuestionTimes={getListeningTimeTaken()}
              />
            </FlexDiv>
          </FlexDiv>
        </WhiteDiv>
      </FlexDiv>
    </>
  ) : (
    <FlexDiv style={{ height: "100vh" }}>
      <LoadingModal />
    </FlexDiv>
  );
};

export default Feedback;
