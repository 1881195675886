import { Box, CircularProgress, Modal, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Snackbar } from "@mui/joy";
import moment from "moment";
import { CLOCK, QUESTIONS } from "../../constants/Mocktests";
import SwiftUniLogo from "../../assets/images/swiftuniLogoo.svg";
import {
  EndTest,
  GetAllMocktestQuestion,
  GetPendingMocktestQuestion,
  Timeout,
} from "../../Client/request";
import Writing from "./Writing/WritingSectional";
import Speaking from "./Speaking/SpeakingSectional";
import Reading from "./Reading/ReadingSectional";
import Listening from "./Listening/ListeningSectional";
import CompatibilityModal from "./CompatibilityModal";
import WelcomeSectional from "./WelcomeSectional";
import RemainingTime from "./RemainingTimeSectional";
import useTimer from "../../hooks/useTimer";
import useStopwatch from "../../hooks/useStopwatchSectional";
import toast from "react-hot-toast";

export default function MockTestSectional() {
  const location = useLocation();
  const { Exam } = location.state || {};
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const isMobile = useMediaQuery("(max-width:450px)");
  const isLaptopTwo = useMediaQuery("(max-width:1000px)");
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(true);
  const [startingCategory, setStartingCategory] = useState(null);
  const [MockTestAttemptID, setMockTestAttemptID] = useState();
  const [snackbarModel, setsnackbarModel] = useState(false);
  const [Endloader, setEndloader] = useState(false);
  const [timeoutModel, setTimeoutModel] = useState(false);
  const [showRemainingTime, setShowRemainingTime] = useState(null);
  const [commulative, setCommulative] = useState(0);
  const [prevElapsedTime, setPrevElapsedTime] = useState(0);
  const [isTestStarted, setIsTestStarted] = useState(false);
  const [RemainingListeningTime, setRemainingListeningTime] = useState(0);
  const [isResuming, setIsResuming] = useState(false);
  const [sstAttemptedCount, setSSTAttemptedCount] = useState(0);
  const [summarizeSpokenTextCount, setSummarizeSpokenTextCount] = useState(0);
  const [sstTimeAdded, setSSTTimeAdded] = useState(false);
  // const [currentCategory, setCurrentCategory] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(
    questions.length > 0 ? questions[0].Category : null
  );
  const [isOnWelcomePage, setIsOnWelcomePage] = useState(true);
  const [toastShown, setToastShown] = useState(false);
  const [isPrevElapsedTimeAdded, setIsPrevElapsedTimeAdded] = useState(false);

  useEffect(() => {
    if (!Exam) {
      toast.error("Redirecting...");
      navigate("/MockTest");
    }
  }, [Exam, navigate]);

  if (!Exam) {
    return null;
  }


  useEffect(() => {
    if (!isOnWelcomePage && !toastShown) {
      setTimeout(() => {
        {Exam.ResumeTestId ?
          (toast.success("Test resumed. Good luck!"))
          :
          (toast.success("Test started. Good luck!"))
        }
      }, 200);
      setToastShown(true);
    }
  }, [isOnWelcomePage, toastShown]);


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.12)",
    borderRadius: "24px",
    p: isMobile ? 2 : 4,
    height: "80vh",
    overflowY: "auto",
  };
  
  const {
    elapsedTime,
    startStopwatch,
    stopStopwatch,
    resetStopwatch,
    addTime,
    questionsTime,
    resetQuestionTimer,
  } = useStopwatch();

  const {
    stopTimer,
  } = useTimer();

  const individualTimes = JSON.parse(Exam.IndividualTimes);
  const {
    speaking_time,
    writing_time,
    reading_time,
    listening_time,
    mock_test_type,
    mock_test_type_id,
  } = individualTimes;

  const speakingTime = speaking_time;
  const writingTime = writing_time;
  const readingTime = reading_time;
  const listeningTime = listening_time;
  const mockTestType = mock_test_type;
  const mockTestTypeId = mock_test_type_id;

  useEffect(() => {
    console.log("Step:", step);
  }, [step]);

  useEffect(() => {
    console.log("Is Test Started:", isTestStarted);
  }, [isTestStarted]);


  // useEffect(() => {
  //   if (prevElapsedTime > 0) {
  //     addTime(prevElapsedTime); 
  //     // setCommulative(prevElapsedTime);
  //     setCommulative((prev) => prev + prevElapsedTime);
  //     console.log("Added Previous Elapsed Time to Stopwatch:", prevElapsedTime);
  //   }
  // }, [prevElapsedTime]);

  const handleAddElapsedTime = (category) => {
    if (prevElapsedTime > 0 && !isPrevElapsedTimeAdded) {
      if (category === "Listening") {
        console.log(`Adding Previous Elapsed Time for Listening:`, prevElapsedTime);
      } else {
        console.log(`Adding Previous Elapsed Time for ${category}:`, prevElapsedTime);
      }
      addTime(prevElapsedTime);
      setCommulative((prev) => prev + prevElapsedTime);
      setIsPrevElapsedTimeAdded(true);
    } else {
      console.log(`Time not added for ${category}. Either already added or prevElapsedTime is 0.`);
    }
  };
  
  
  useEffect(() => {
    if (currentCategory !== "Listening") {
      handleAddElapsedTime(currentCategory);
    }
  }, [prevElapsedTime, isPrevElapsedTimeAdded, currentCategory]);

  // useEffect to Reset the stopwatch for the new category
  useEffect(() => {
    if (questions.length === 0 || step <= 0 || isResuming) return;
    // Determine the current category
    const newCategory = questions[step - 1]?.Category;
    // Check if the category has changed
    if (newCategory !== currentCategory) {
      const newCategoryTime = getTimeLimit(newCategory);
      // Reset the stopwatch for the new category
      // resetStopwatch(moment.duration(newCategoryTime, "minutes"));
      setCommulative(0);
      resetStopwatch(moment.duration(newCategoryTime, "minutes").asMilliseconds());
      console.log(`Category changed to: ${newCategory}. Stopwatch reset.`);
      setCurrentCategory(newCategory);
    }
  }, [step, questions, currentCategory]);

  useEffect(() => {
    if (isResuming && step > 0) {
      console.log("Resumption complete. Resetting isResuming...");
      setIsResuming(false); // Reset resuming state after step is updated
    }
  }, [isResuming, step]);

  useEffect(() => {
    if (isTestStarted) {
      startStopwatch();
      setIsTestStarted(false);
    }
  }, [isTestStarted]);

  useEffect(() => {
    if (questions.length > 0) {
      const sstQuestions = questions.filter((q) => q.Category === "Listening" && q.SubCategory === "Summarize Spoken Text");
      const totalSSTCount = sstQuestions.length;
      setSummarizeSpokenTextCount(totalSSTCount);

      const sumSpokenTextTime = totalSSTCount * 10;
      const listeningOtherQsTime = listeningTime - sumSpokenTextTime;
      setRemainingListeningTime(listeningOtherQsTime);

      const attemptedSSTCount = sstQuestions.filter((q) => q.IsAttempted).length;
      setSSTAttemptedCount(attemptedSSTCount);
    }
  }, [questions, listeningTime]);

  // // useEffect to detect all sst questions attempted, then reset stop watch
  // useEffect(() => {
  //     if (summarizeSpokenTextCount > 0 && !sstTimeAdded && sstAttemptedCount === summarizeSpokenTextCount) {
  //     // const sstTotalTime = summarizeSpokenTextCount * 10; 
  //     // console.log(`Added SST Total Time: ${sstTotalTime} minutes for ${summarizeSpokenTextCount} SST questions.`);
  //     setSSTTimeAdded(true);
     
   
  //     if(prevElapsedTime > 0) {
  //       setCommulative(0);
  //       console.log(`SST Questions Done. prevElapsedTime Present. Stop Watch Reseting for Time: ${RemainingListeningTime}`);
  //       resetStopwatch(moment.duration(RemainingListeningTime, "minutes").asMilliseconds());
  //       addTime(prevElapsedTime);
  //       setPrevElapsedTime(0); 
  //       // setCommulative(prevElapsedTime);
  //       setCommulative((prev) => prev + prevElapsedTime);
  //     } else {
  //       setCommulative(0);
  //       console.log(`SST Questions Done. prevElapsedTime Not Present. Stop Watch Reseting for Time: ${RemainingListeningTime}`);
  //       resetStopwatch(moment.duration(RemainingListeningTime, "minutes").asMilliseconds());
  //     }

  //     if(!isOnWelcomePage){
  //       startStopwatch();
  //     }
  //   }
  // }, [sstAttemptedCount, summarizeSpokenTextCount, sstTimeAdded, RemainingListeningTime, isOnWelcomePage, prevElapsedTime, isResuming]);
  

  useEffect(() => {
    if (summarizeSpokenTextCount > 0 && !sstTimeAdded && sstAttemptedCount === summarizeSpokenTextCount) {

      setSSTTimeAdded(true);

      if (prevElapsedTime > 0 && !isPrevElapsedTimeAdded) {
        console.log(`SST Questions Done. prevElapsedTime Present. Stop Watch Reseting for Time: ${RemainingListeningTime}`);
        setCommulative(0);
        resetStopwatch(moment.duration(RemainingListeningTime, "minutes").asMilliseconds());
        handleAddElapsedTime(currentCategory);
        setIsPrevElapsedTimeAdded(true);
  
        if (!isOnWelcomePage) {
          console.log("Starting stopwatch from parent for remaining listening...");
          stopTimer();
          startStopwatch();
        }
      } 
      
      else {
        console.log(`SST Questions Done. prevElapsedTime Not Present. Stop Watch Reseting for Time: ${RemainingListeningTime}`);
        setCommulative(0);
        resetStopwatch(moment.duration(RemainingListeningTime, "minutes").asMilliseconds());
  
        if (!isOnWelcomePage) {
          console.log("Starting stopwatch from parent for remaining listening...");
          stopTimer();
          startStopwatch();
        }
      }
    }
  }, [
    sstAttemptedCount,
    summarizeSpokenTextCount,
    sstTimeAdded,
    RemainingListeningTime,
    isOnWelcomePage,
    prevElapsedTime,
    isPrevElapsedTimeAdded,
    isResuming,
    currentCategory,
  ]);

  // const getTimeLimit = (category) => {
  //   switch (category) {
  //     case "Speaking":
  //       return speakingTime;
  //     case "Writing":
  //       return writingTime;
  //     case "Reading":
  //       return readingTime;
  //     case "Listening":
  //       return RemainingListeningTime;
  //     default:
  //       return 0;
  //   }
  // };

  const getTimeLimit = (question) => {
    const category = question.Category;
    const subCategory = question.SubCategory;
    switch (category) {
      case "Speaking":
        return speakingTime;
      case "Writing":
        return writingTime;
      case "Reading":
        return readingTime;
      case "Listening":
        if (subCategory === "Summarize Spoken Text") {
          return summarizeSpokenTextCount * 10; 
        } else {
          return RemainingListeningTime;
        }
      default:
        return 0;
    }
  };
  
  // //timeout useEffect
  // useEffect(() => {
  //   if (questions.length === 0) return;

  //   const currentCategory = questions[step - 1]?.Category;
  //   // const currentSubCategory = questions[step - 1]?.SubCategory;

  //   // Find the index where the category changes
  //   let endIndex = step;
  //   for (let i = step; i < questions.length; i++) {
  //     if (questions[i].Category !== currentCategory) {
  //       endIndex = i;
  //       break;
  //     }
  //   }

  //   // If no category change is found, set endIndex to the end of the questions array
  //   if (endIndex === step) {
  //     endIndex = questions.length;
  //   }

  //   const timeLimit = getTimeLimit(currentCategory);

  //   if (elapsedTime >= moment.duration(timeLimit, "minutes").asMilliseconds()) {
  //     // const initialCategory = currentCategory;
  //     if (!currentCategory) return;

  //     // Slice the remaining questions in the current category
  //     const slicedArray = questions.slice(step - 1, endIndex);

  //     // Call the timeout API for the remaining questions in the current category
  //     const payload = {
  //       mock_test_attempt_id: MockTestAttemptID
  //         ? MockTestAttemptID
  //         : Exam.ResumeTestId,
  //       is_ptecore: false,
  //       response_obj: JSON.stringify(slicedArray),
  //     };
  //     Timeout(payload);
  //     setTimeoutModel(true);

  //     if (endIndex >= questions.length) {
  //       // No more questions left, end the test
  //       setEndloader(true);
  //       const Endpayload = {
  //         mock_test_attempt_id: MockTestAttemptID
  //           ? MockTestAttemptID
  //           : Exam.ResumeTestId,
  //         end_time: new Date().toLocaleDateString(),
  //       };
  //       EndTest(Endpayload).then((res) => {
  //         if (res.data) {
  //           navigate(
  //             `/mt-score-viewscore-sectional/${
  //               MockTestAttemptID ? MockTestAttemptID : Exam.ResumeTestId
  //             }`
  //           );
  //           setEndloader(false);
  //         }
  //       });
  //     } else {
  //       // There are more questions in the next category
  //       // Move to the next category
  //       const FinalIndex = endIndex;
  //       setStep(FinalIndex + 1);

  //       // Determine the next category and reset the stopwatch
  //       const nextCategory = questions[FinalIndex]?.Category;
  //       const nextCategoryTime = getTimeLimit(nextCategory);
  //       // Reset the stopwatch with the time duration for the new category
  //       console.log('Found Next Category in Else Part of Timeout API:', nextCategory)
  //       console.log('Resting Time For This Category is:', nextCategoryTime)
  //       // resetStopwatch(moment.duration(nextCategoryTime, "minutes"));

  //       switch (nextCategory) {
  //         case "Speaking":
  //           setStartingCategory(1);
  //           break;
  //         case "Writing":
  //           setStartingCategory(2);
  //           break;
  //         case "Reading":
  //           setStartingCategory(3);
  //           break;
  //         case "Listening":
  //           setStartingCategory(4);
  //           break;
  //         default:
  //           break;
  //       }

  //       setIsTestStarted(false);
  //     }
  //   }
  // }, [elapsedTime]);

  useEffect(() => {
    //timeout useEffect
    // console.log("Elapsed Time in Paren Component:", elapsedTime);
    if (questions.length === 0 || step <= 0) return;

    const currentCategory = questions[step - 1]?.Category;
    const currentQuestion = questions[step - 1];
    // Find the index where the category changes
    let endIndex = step;
    for (let i = step; i < questions.length; i++) {
      if (questions[i].Category !== currentCategory) {
        endIndex = i;
        break;
      }
    }

    // If no category change is found, set endIndex to the end of the questions array
    if (endIndex === step) {endIndex = questions.length}

    // const timeLimit = getTimeLimit(currentCategory);
    const timeLimit = getTimeLimit(currentQuestion);

    if (elapsedTime >= moment.duration(timeLimit, "minutes").asMilliseconds()) {
      if (!currentCategory) return;
      // Call Timeout API only for the current category's unanswered questions
      const slicedArray = questions.slice(step - 1, endIndex);
      const payload = {
        mock_test_attempt_id: MockTestAttemptID
          ? MockTestAttemptID
          : Exam.ResumeTestId,
        is_ptecore: false,
        response_obj: JSON.stringify(slicedArray),
      };
      Timeout(payload);
      setTimeoutModel(true);

      // Move to the next category if questions remain
      if (endIndex < questions.length) {
        const FinalIndex = endIndex;
        setStep(FinalIndex + 1);

        const nextCategory = questions[FinalIndex]?.Category;

        switch (nextCategory) {
          case "Speaking":
            setStartingCategory(1);
            break;
          case "Writing":
            setStartingCategory(2);
            break;
          case "Reading":
            setStartingCategory(3);
            break;
          case "Listening":
            setStartingCategory(4);
            break;
          default:
            break;
        }
      } else {
        // End the test if no more questions remain
        setEndloader(true);
        const Endpayload = {
          mock_test_attempt_id: MockTestAttemptID
            ? MockTestAttemptID
            : Exam.ResumeTestId,
          end_time: new Date().toLocaleDateString(),
        };
        EndTest(Endpayload).then((res) => {
          if (res.data) {
            navigate(
              `/mt-score-viewscore-sectional/${
                MockTestAttemptID ? MockTestAttemptID : Exam.ResumeTestId
              }`
            );
            setEndloader(false);
          }
        });
      }
    }
  }, [elapsedTime]);

  useEffect(() => {
    if (Exam.ResumeTestId) {
      setIsResuming(true);
      // toast.success("You Resumed The Mock Test Exam.");
      GetPendingMocktestQuestion({ id: Exam.ResumeTestId }).then((res) => {
        if (res.responseCode === 200) {
          setQuestions(res.response || []);

          // NewIndex is the first unattempted question
          const NewIndex = res?.response.findIndex(
            (element) => element.IsAttempted === false
          );
          const startingCategory = res?.response[NewIndex]?.Category;
          setCurrentCategory(startingCategory);

          setStep(NewIndex + 1);


          // // Retrieve previous cumulative time
          // const previousElapsedTime = res?.response[NewIndex - 1]?.AllTimes
          // ? JSON.parse(res.response[NewIndex - 1].AllTimes)?.commulativeTime
          // : 0;
                    
          // console.log("previous Elapsed Time After Resume: ", previousElapsedTime);
          // if (previousElapsedTime > 0) {setPrevElapsedTime(previousElapsedTime);}


          
// Filter questions to include only those in the current category
const currentCategoryQuestions = res.response.filter(
  (q) => q.Category === startingCategory
);

// Special handling for Listening category
let lastAttemptedQuestion;

if (startingCategory === "Listening") {
  // Exclude "Summarize Spoken Text" questions
  const listeningQuestionsExcludingSST = currentCategoryQuestions.filter(
    (q) => q.SubCategory !== "Summarize Spoken Text"
  );

  // Find the last attempted question in Listening (excluding SST)
  lastAttemptedQuestion = listeningQuestionsExcludingSST
    .reverse() // Reverse to get the last attempted question first
    .find((q) => q.IsAttempted);
} else {
  // For other categories, find the last attempted question normally
  lastAttemptedQuestion = currentCategoryQuestions
    .reverse() // Reverse to get the last attempted question first
    .find((q) => q.IsAttempted);
}

// Retrieve cumulative time from the last attempted question
const cumulativeTime = lastAttemptedQuestion?.AllTimes
  ? JSON.parse(lastAttemptedQuestion.AllTimes)?.commulativeTime || 0
  : 0;

console.log("Cumulative Elapsed Time for Current Category:", cumulativeTime);

if (cumulativeTime > 0) {
  setPrevElapsedTime(cumulativeTime);
}



          switch (startingCategory) {
            case "Speaking":
              setStartingCategory(1);
              break;
            case "Writing":
              setStartingCategory(2);
              break;
            case "Reading":
              setStartingCategory(3);
              break;
            case "Listening":
              setStartingCategory(4);
              break;
            default:
              break;
          }
        }
      });
    } else {
      GetAllMocktestQuestion({ id: Exam.MockTestId }).then((res) => {
        if (res.responseCode === 200) {
          setMockTestAttemptID(res.response.MockTestAttemptId);
          setQuestions(res.response.obj || []);
          if (res.response.obj && res.response.obj.length > 0) {
            const NewIndex = 0;
            const startingCategory = res.response.obj[NewIndex]?.Category;
            setCurrentCategory(startingCategory);
            switch (startingCategory) {
              case "Speaking":
                setStartingCategory(1);
                break;
              case "Writing":
                setStartingCategory(2);
                break;
              case "Reading":
                setStartingCategory(3);
                break;
              case "Listening":
                setStartingCategory(4);
                break;
              default:
                break;
            }
          }
        } else if (res.responseCode === 400) {
          setsnackbarModel(true);
          setTimeout(() => navigate("/MockTest"), 1000);
        }
      });
    }
  }, []);

  // useEffect to handle timers based on step and questions
  useEffect(() => {
    if (questions.length === 0 || step <= 0) return;

    const currentQuestion = questions[step - 1];
    const currentCategory = currentQuestion?.Category;
    const currentSubCategory = currentQuestion?.SubCategory;

    if (currentCategory === "Writing") {
      let timeDuration = 0;
      if (currentSubCategory === "Write Essay") timeDuration = 20;
      else if (currentSubCategory === "Summarize Written Text") timeDuration = 10;
      else if (currentSubCategory === "Write Email") timeDuration = 9;
      setShowRemainingTime(timeDuration);
    } else if (currentCategory === "Listening" && currentSubCategory === "Summarize Spoken Text") {
      setShowRemainingTime(10);
    } else {
      setShowRemainingTime(null);
    }
  }, [step, questions]);



  useEffect(() => {
    if (questions.length === 0 || step <= 0) return;
    const currentCategory = questions[step - 1]?.Category;
    const isWelcomePage =
      (currentCategory === "Speaking" && startingCategory === 1) ||
      (currentCategory === "Writing" && startingCategory === 2) ||
      (currentCategory === "Reading" && startingCategory === 3) ||
      (currentCategory === "Listening" && startingCategory === 4);
    setIsOnWelcomePage(isWelcomePage);
  }, [step, startingCategory, questions]);

  const formatElapsedTime = (elapsedTime) => {
    return elapsedTime >= 3600000
      ? moment.utc(elapsedTime).format("HH:mm:ss")
      : moment.utc(elapsedTime).format("mm:ss");
  };

  const getFormattedTotalTime = (category) => {
    const totalMinutes =
      category === "Speaking"
        ? speakingTime
        : category === "Reading"
        ? readingTime
        : category === "Listening"
        ? RemainingListeningTime
        : 0;

    const totalMilliseconds = totalMinutes * 60 * 1000;
    return totalMilliseconds >= 3600000
      ? moment.utc(totalMilliseconds).format("HH:mm:ss")
      : moment.utc(totalMilliseconds).format("mm:ss");
  };


  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={4000}
        open={timeoutModel}
        variant="solid"
        color="danger"
        onClose={() => setTimeoutModel(false)}
      >
        Time ended for this section
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={4000}
        open={snackbarModel}
        variant="soft"
        color="danger"
        onClose={() => setsnackbarModel(false)}
      >
        You have already started this test !!
      </Snackbar>
      <Modal
        open={open}
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          backdropFilter: "blur(3px)",
        }}
        onClose={(event, reason) => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return;
          }
          setOpen(false);
        }}
      >
        <Box sx={style}>
          <CompatibilityModal setOpen={setOpen} test={Exam} />
        </Box>
      </Modal>
      {/* header */}
      {questions.length > 0 && (
        <div
          style={{
            padding: isLaptopTwo ? "15px 10px" : "5px 100px",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <img src={SwiftUniLogo} alt="logo" />
            {!isMobile && <h1>Mock Test</h1>}
          </div>

          {/* <span>
                  {formatElapsedTime(elapsedTime)} / 
                  {questions.length > 0 && getFormattedTotalTime(questions[step - 1]?.Category)}
          </span> */}

          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div style={{ display: "flex", gap: "10px" }}>
              {CLOCK}
              {showRemainingTime ? (
                <RemainingTime
                  showRemainingTime={showRemainingTime}
                  setShowRemainingTime={setShowRemainingTime}
                  stopStopwatch={stopStopwatch}
                  addTime={addTime}
                  Category={questions[step - 1].Category}
                  step={step}
                  setStep={setStep}
                  startingCategory={startingCategory}
                  questions={questions}
                  isOnWelcomePage={isOnWelcomePage}
                />
              ) : (
                <span>
                  {formatElapsedTime(elapsedTime)} / 
                  {questions.length > 0 && getFormattedTotalTime(questions[step - 1]?.Category)}
                </span>
              )}
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              {QUESTIONS}
              <span>
                {step} of {questions.length}
              </span>
            </div>
          </div>
        </div>
      )}
      {Endloader ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            width: "100vw",
            height: "80vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : questions.length > 0 ? (
        <>
          {questions[step - 1].Category === "Speaking" ? (
            startingCategory === 1 ? (
              <WelcomeSectional
                Exam={Exam.MockTestName}
                startingCategory={startingCategory}
                setNextStartingCategory={setStartingCategory}
                name={"Speaking"}
                totalTime={speakingTime}
                setIsTestStarted={setIsTestStarted}
                isResuming={!!Exam.ResumeTestId}
                questions={questions}
                step={step}
              />
            ) : (
              <Speaking
                ExamId={Exam.ResumeTestId}
                quest={questions[step - 1]}
                step={step}
                setStep={setStep}
                MockTestAttemptID={MockTestAttemptID}
                totalQuestions={questions.length}
                commulative={commulative}
                setCommulative={setCommulative}
                mockTestType={mockTestType}
                mockTestTypeId={mockTestTypeId}

                elapsedTime={questionsTime}
                startStopwatch={startStopwatch}
                stopStopwatch={stopStopwatch}
                resetElapsedTime={resetQuestionTimer}
              />
            )
          ) : questions[step - 1].Category === "Writing" ? (
            startingCategory === 2 ? (
              <WelcomeSectional
                Exam={Exam.MockTestName}
                startingCategory={startingCategory}
                setNextStartingCategory={setStartingCategory}
                name={"Writing"}
                totalTime={writingTime}
                setIsTestStarted={setIsTestStarted}
                isResuming={!!Exam.ResumeTestId}
                questions={questions}
                step={step}
              />
            ) : (
              <Writing
                ExamId={Exam.ResumeTestId}
                quest={questions[step - 1]}
                step={step}
                setStep={setStep}
                MockTestAttemptID={MockTestAttemptID}
                totalQuestions={questions.length}
                commulative={commulative}
                setCommulative={setCommulative}
                isResuming={!!Exam.ResumeTestId}
                mockTestType={mockTestType}
                mockTestTypeId={mockTestTypeId}

                elapsedTime={questionsTime}
                startStopwatch={startStopwatch}
                stopStopwatch={stopStopwatch}
                resetElapsedTime={resetQuestionTimer}
              />
            )
          ) : questions[step - 1].Category === "Reading" ? (
            startingCategory === 3 ? (
              <WelcomeSectional
                Exam={Exam.MockTestName}
                startingCategory={startingCategory}
                setNextStartingCategory={setStartingCategory}
                name={"Reading"}
                setCommulative={setCommulative}
                totalTime={readingTime}
                setIsTestStarted={setIsTestStarted}
                isResuming={!!Exam.ResumeTestId}
                questions={questions}
                step={step}
              />
            ) : (
              <Reading
                ExamId={Exam.ResumeTestId}
                quest={questions[step - 1]}
                step={step}
                setStep={setStep}
                MockTestAttemptID={MockTestAttemptID}
                totalQuestions={questions.length}
                commulative={commulative}
                setCommulative={setCommulative}
                mockTestType={mockTestType}
                mockTestTypeId={mockTestTypeId}

                elapsedTime={questionsTime}
                startStopwatch={startStopwatch}
                stopStopwatch={stopStopwatch}
                resetElapsedTime={resetQuestionTimer}
              />
            )
          ) : questions[step - 1].Category === "Listening" ? (
            startingCategory === 4 ? (
              <WelcomeSectional
                Exam={Exam.MockTestName}
                startingCategory={startingCategory}
                setNextStartingCategory={setStartingCategory}
                name={"Listening"}
                totalTime={listeningTime}
                RemainingListeningTime={RemainingListeningTime}
                summarizeSpokenTextTime={summarizeSpokenTextCount * 10}
                setIsTestStarted={setIsTestStarted}
                isResuming={!!Exam.ResumeTestId}
                questions={questions}
                step={step}
              />
            ) : (
              <Listening
                ExamId={Exam.ResumeTestId}
                quest={questions[step - 1]}
                step={step}
                setStep={setStep}
                MockTestAttemptID={MockTestAttemptID}
                totalQuestions={questions.length}
                commulative={commulative}
                setCommulative={setCommulative}
                setShowRemainingTime={setShowRemainingTime}
                mockTestType={mockTestType}
                mockTestTypeId={mockTestTypeId}
                incrementSSTAttemptedCount={() => setSSTAttemptedCount((prev) => prev + 1)}

                elapsedTime={questionsTime}
                startStopwatch={startStopwatch}
                stopStopwatch={stopStopwatch}
                resetElapsedTime={resetQuestionTimer}
              />
            )
          ) : null}
        </>
      ) : null}
    </div>
  );
}
