import React, {useState} from "react";
import {
  EndScoreCard,
  EndScoreImgDiv,
  EndScoreQuestionText,
  EndScoreIdText,
  EndScoreScoresDiv,
  MyWorkBlueDiv,
  MyWorkBlueDivImg,
  MyWorkBlueDivText,
  MyWorkSmallBlueDiv,
  MyWorkBlueDivAi,
  MyWorkSmallBlueDivAi,
  FlexDivForBlueDivsOnly,
  BlueDivsQText,
  EndScoreCardText,
  EndScoreQuestionSubCat,
  BlueDivsQTextSubCat,
} from "./style";
import { FlexDiv } from "../../assets/styles/style";
import ReadAloudSvg from "../../assets/images/readaloudsvg.svg";
import AI_Score from "../../assets/images/aiscoresvg.svg";
import ReadingSvg from "../../assets/images/reading.svg";
import WritingSvg from "../../assets/images/writing.svg";
import ListenSvg from "../../assets/images/listening.svg";
import ScoreCard from './ScoreCard';
import { v4 as uuidv4 } from 'uuid';
import { findGramMistakes, findMistakeIndexes } from "../Writing/AiSummaryScorePopup";

const typeMap = {
  'reading': 'Reading',
  'writing': 'Writing',
  'listening': 'Listening',
  'speaking': 'Speaking'
}

const pteType = localStorage.getItem("pte-type") || "pte academic";
const is_ptecore = pteType === "pte academic" ? false : true;


const EndTestScoreCard = ({ data, tests, type}) => {
  const [scorecardOpen, setScorecardOpen] = useState(false);
  const [selectedTestData, setSelectedTestData] = useState(null);

  const handleOpenScoreCard = (testData) => {
    setSelectedTestData(testData);
    setScorecardOpen(true);
  };

  const handleCloseScoreCard = () => {
    setScorecardOpen(false);
    setSelectedTestData(null);
  };

  const getData = () => tests.filter(test => test.UserResponse.Category === typeMap[type]);

  const getType = (color = false) => {
    const typeMap = {
      reading: { color: '#AD826E', svg: ReadingSvg },
      writing: { color: '#FF5D5D', svg: WritingSvg },
      listening: { color: '#868EAF', svg: ListenSvg },
      default: { color: '#49d7f2', svg: ReadAloudSvg }
    };
  
    const typeData = typeMap[type] || typeMap.default;
    return color ? typeData.color : typeData.svg;
  };

  function getFirstLetters(input) {
    const words = input.split(' ');
    const firstLetters = words.map(word => word.charAt(0)).join('');
    return firstLetters.length > 4 ? firstLetters.toUpperCase().slice(-3) : firstLetters.toUpperCase();
  }

  const getScore = (data) => {
    let val; 

    if (type === 'speaking') {
      if (data.UserResponse.SubCategory === "Read Aloud") {
        let res = JSON.parse(data.UserResponse.AI_response);
        return `${(res?.content_score || 0) + (res?.fluency_score || 0) + (res?.pronounciation_score || 0)}/15`;
      } else if (data.UserResponse.SubCategory === "Describe Image") {
        let res = JSON.parse(data.UserResponse.AI_response);
        return `${(res?.content_score || 0) + (res?.fluency_score || 0) + (res?.pronounciation_score || 0)}/15`;
      } else if (data.UserResponse.SubCategory === "Re-tell Lecture") {
        let res = JSON.parse(data.UserResponse.AI_response);
        return `${(res?.content_score || 0) + (res?.fluency_score || 0) + (res?.pronounciation_score || 0)}/15`;
      } else if (data.UserResponse.SubCategory === "Answer Short Question") {
        let res = JSON.parse(data.UserResponse.AI_response);
        return `${(res?.content_score || 0) + (res?.fluency_score || 0) + (res?.pronunciation_score || 0)}/1`;
      } else if (data.UserResponse.SubCategory === "Repeat Sentence") {
        let res = JSON.parse(data.UserResponse.AI_response);
        return `${(res?.content_score || 0) + (res?.fluency_score || 0) + (res?.pronunciation_score || 0)}/13`;
      }
    }
    
    else if(type === 'writing'){
      if(data.UserResponse.SubCategory === "Fill in the Blanks"){ 
        return data.UserResponse.enableSkillsData[0].score
      }
      
      else if(data.UserResponse.SubCategory === "Write from Dictation"){
        let res = JSON.parse(data.UserResponse.AI_response)
        return `${res.writing_score}/${res.total_score}`; 
      }
      
      else if(data.UserResponse.SubCategory === "Write Essay"){
        let res = JSON.parse(data.UserResponse.AI_response);
        let totalSumScore = res.spelling_score + res.content_score + res.vocab_range_score + res.form_score + res.development_structure_coherence_score + res.general_linguistic_range_score
        let gramMistakes = 2;
        if((res.content_score + res.form_score) === 0){
          gramMistakes = 0;
          totalSumScore = 0;
        }else{       
          let tmpTwoMistakes = res?.["temp_mistakes"]["mistakes"] ? res?.["temp_mistakes"]["mistakes"] : [];
          let correctedWords = res["corrected words"];
          let userRes = data.UserResponse;                  
          if (
            correctedWords &&
            typeof correctedWords === "object" &&
            !Array.isArray(correctedWords)
          ) {
            Object.entries(res["corrected words"]).forEach(
              ([key, value]) => {
                let newMistake = {
                  explanation: `Incorrect: ${key}`,
                  error_name: 'Spellings Mistake',
                  corrected: `Correct: ${value}`,
                  phrase_with_mistake: key,
                  exact_mistake_word: key,
                  incorrect: key
                };
                tmpTwoMistakes.push(newMistake);
              }
            );
          } 
          const val = findMistakeIndexes(userRes.UserResponse, [], tmpTwoMistakes);
          if(val.reqIndex?.length){
            let spellcount = Object.keys(res['corrected words']).length;
            let tmpOne = val.reqIndex?.length - spellcount;
            gramMistakes = gramMistakes - (tmpOne * 0.5);
          }
          totalSumScore = gramMistakes > 0 ? totalSumScore + gramMistakes : totalSumScore
        }
        return `${totalSumScore > 0 ? totalSumScore.toFixed(1) : 0}/15`;
      }

      else if(data.UserResponse.SubCategory === "Summarize Written Text"){
        let res = JSON.parse(data.UserResponse.AI_response);
        let tmpRes = findGramMistakes(data.UserResponse.UserResponse, true);
        let totalSumScore = res.content_score + res.vocab_range_score + res.form_score
        let gramMistakes;
        if((res.content_score + res.form_score) === 0){
          gramMistakes = 0;
          totalSumScore = 0;
        }else{          
          gramMistakes =  res.temp_mistakes?.mistakes && Array.isArray(res.temp_mistakes.mistakes) ? res.temp_mistakes?.mistakes.length : 0;
          gramMistakes += Object.keys(res['corrected words']).length
          gramMistakes += tmpRes;
          gramMistakes = 2 - (gramMistakes * 0.5)
          totalSumScore = gramMistakes > 0 ? totalSumScore + gramMistakes : totalSumScore
        }
        return `${totalSumScore > 0 ? totalSumScore : 0}/7`;
      }
      
      else if(data.UserResponse.SubCategory === "Summarize Spoken Text"){
        let res = JSON.parse(data.UserResponse.AI_response);
        let totalSumScore = res.content_score + res.vocab_range_score + res.form_score
        let gramMistakes =  res.temp_mistakes?.mistakes && Array.isArray(res.temp_mistakes.mistakes) ? res.temp_mistakes?.mistakes.length : 0;
        gramMistakes += Object.keys(res['corrected words']).length
        gramMistakes = 2 - (gramMistakes * 0.5)
        totalSumScore = gramMistakes > 0 ? totalSumScore + gramMistakes : totalSumScore
        return `${totalSumScore > 0 ? totalSumScore : 0}/7`;
      }
      
      else{
          return data.UserResponse.enableSkillsData[0].score
      }
    }    
    else if(type === 'reading'){
      if(data.UserResponse.SubCategory === "Multiple Choice, Multiple Answers"){
        return data.UserResponse.enableSkillsData[0].score        
      }else if(data.UserResponse.SubCategory === "Highlight Correct Summary"){
        return data.UserResponse.enableSkillsData[0].score        
      }else if(data.UserResponse.SubCategory === "Highlight Incorrect Words"){
        return data.UserResponse.enableSkillsData[0].score        
      }else if(data.UserResponse.SubCategory === "Read Aloud"){
        val = '1/13';
      }else if(data.UserResponse.SubCategory === "Summarize Written Text"){
        let res = JSON.parse(data.UserResponse.AI_response);
        let totalSumScore = res.content_score + res.vocab_range_score + res.form_score
        let gramMistakes =  res.temp_mistakes?.mistakes && Array.isArray(res.temp_mistakes.mistakes) ? res.temp_mistakes?.mistakes.length : 0;
        gramMistakes += Object.keys(res['corrected words']).length
        gramMistakes = 2 - (gramMistakes * 0.5)
        totalSumScore = gramMistakes > 0 ? totalSumScore + gramMistakes : totalSumScore
        return `${totalSumScore > 0 ? totalSumScore : 0}/7`;
      }else if(data.UserResponse.SubCategory === "Reading: Fill in the Blanks"){
        return data.UserResponse.enableSkillsData[0].score
      }else if(data.UserResponse.SubCategory === "Multiple Choice, Single Answer"){
        return data.UserResponse.enableSkillsData[0].score        
      }else if(data.UserResponse.SubCategory === "Re-order Paragraphs"){
        let obtainedScore = data?.UserResponse?.submissionResult?.score;
        let totalScore = data?.UserResponse?.submissionResult?.correctIndexes?.length;
        return `${obtainedScore > 0 ? obtainedScore - 1 : obtainedScore}/${totalScore - 1}`;
      }else{
        return data.UserResponse.enableSkillsData[0].score
      }
    }else{
      if(data.UserResponse.SubCategory === "Fill in the Blanks"){
        return data.UserResponse.enableSkillsData[0].score
      }else if(data.UserResponse.SubCategory === "Listening: Multiple Choice, Single Answer"){
        return data.UserResponse.enableSkillsData[0].score
      }else if(data.UserResponse.SubCategory === "Highlight Correct Summary"){
        return data.UserResponse.enableSkillsData[0].score        
      }else if(data.UserResponse.SubCategory === "Select Missing Word"){
        return data.UserResponse.enableSkillsData[0].score        
      }else if(data.UserResponse.SubCategory === "Highlight Incorrect Words"){
        return data.UserResponse.enableSkillsData[0].score        
      }else if (data.UserResponse.SubCategory === "Re-tell Lecture"){
        val = '2/15';
      }else if (data.UserResponse.SubCategory === "Answer Short Question"){
        val = '0/1';
      }else if (data.UserResponse.SubCategory === "Repeat Sentence"){
        val = '1/13';
      }else if (data.UserResponse.SubCategory === "Listening: Multiple Choice, Multiple Answers"){
        return data.UserResponse.enableSkillsData[0].score
      }else if(data.UserResponse.SubCategory === "Summarize Spoken Text"){
        let res = JSON.parse(data.UserResponse.AI_response);
        let userRes = data.UserResponse
        let tmpTwoMistakes = res?.["temp_mistakes"]["mistakes"] ? res?.["temp_mistakes"]["mistakes"] : [];
        let correctedWords = res["corrected words"];        
        if (
          correctedWords &&
          typeof correctedWords === "object" &&
          !Array.isArray(correctedWords)
        ) {
          Object.entries(res["corrected words"]).forEach(
            ([key, value]) => {
              let newMistake = {
                explanation: `Incorrect: ${key}`,
                error_name: 'Spellings Mistake',
                corrected: `Correct: ${value}`,
                phrase_with_mistake: key,
                exact_mistake_word: key,
                incorrect: key
              };
              tmpTwoMistakes.push(newMistake);
            }
          );
        } 
        const val = findMistakeIndexes(userRes.UserResponse, [], tmpTwoMistakes);               

        let totalSumScore = res.content_score + res.vocab_range_score + res.form_score + res.spelling_score;
        let gramMistakes = 0;
        if (val.reqIndex?.length) {
          if(res['spelling_score']){
            gramMistakes = val.reqIndex.length - (2 - res['spelling_score']);
          }else{
            gramMistakes = val.reqIndex.length;
          }
          gramMistakes = 2 - (gramMistakes*0.5)
        }
        if((res.content_score + res.form_score) > 0){
          totalSumScore = gramMistakes > 0 ? totalSumScore + gramMistakes : totalSumScore
        }
        return `${totalSumScore > 0 ? totalSumScore : 0}/10`;
      }else if(data.UserResponse.SubCategory === "Write from Dictation"){
        let res = JSON.parse(data.UserResponse.AI_response)
        return `${res.writing_score}/${res.total_score}`;
      }      
    }

    return val;
  }
  
  const isAI = (data) => !!data.UserResponse?.AI_response;

  const shortNamesMap = {
    "Read Aloud": "RA",
    "Repeat Sentence": "RS",
    "Describe Image": "DI",
    "Re-tell Lecture": "RL",
    "Respond to a Situation": "RTS",
    "Answer Short Question": "ASQ",

    "Summarize Written Text": "SWT",
    "Write Essay": "WE",
    "Write Email": "WE",

 
    "Reading & Writing: Fill in the Blanks": "FIB",
    "Multiple Choice, Multiple Answers": "MCM",
    "Re-order Paragraphs": "ROP",
    "Reading: Fill in the Blanks": "FIB",
    "Multiple Choice, Single Answer": "MCS",

  
    "Summarize Spoken Text": "SST",
    "Listening: Multiple Choice, Multiple Answers": "MCM",
    "Fill in the Blanks": "FIB",
    "Write from Dictation": "WFD",
    "Highlight Correct Summary": "HCS",
    "Highlight Incorrect Words": "HIW",
    "Listening: Multiple Choice, Single Answer": "MCS",
    "Select Missing Word": "SMW",

  };
  

  const getShortName = (subcategory) => {
    return shortNamesMap[subcategory] || 'Test';
  };

  return (
    <>
      {tests && getData().map((test) => (
      <EndScoreCard key={uuidv4()}>
 
          {/* <EndScoreCardText style={{marginLeft:getFirstLetters(test.UserResponse.SubCategory).length>2? '1.5rem' :'', fontSize:getFirstLetters(test.UserResponse.SubCategory).length>2?'16px':''}}>{getFirstLetters(test.UserResponse.SubCategory)}</EndScoreCardText>  */}
        <EndScoreImgDiv>
          <FlexDiv style={{position:'relative'}}>
          <img src={getType()} alt=""  />
          <EndScoreCardText style={{
            // marginLeft: getShortName(test.UserResponse.SubCategory).length > 2 ? '1.5rem' : '',
            fontSize: getShortName(test.UserResponse.SubCategory).length > 2 ? '16px' : '20px'
          }}>
            {getShortName(test.UserResponse.SubCategory)}
          </EndScoreCardText>
          </FlexDiv>
          <FlexDiv style={{ flexDirection: "row", alignItems: "flex-start" }}>
            <EndScoreIdText style={{color: getType(true)}}>{test.UserResponse ? '#' + test.UserResponse.QuestionId : '0'}</EndScoreIdText>
            <EndScoreQuestionText>{test.UserResponse ? test.UserResponse.QuestionName : 'loading'}</EndScoreQuestionText>
            {/* <EndScoreQuestionSubCat>({test.UserResponse ? test.UserResponse.SubCategory : ''})</EndScoreQuestionSubCat> */}
            <BlueDivsQTextSubCat style={{color: getType(true)}}>({test.UserResponse ? test.UserResponse.SubCategory : ''})</BlueDivsQTextSubCat>
          </FlexDiv>
        </EndScoreImgDiv>

        <EndScoreScoresDiv>
          {/* <BlueDivsQText style={{color: getType(true)}}>{data.questionText}</BlueDivsQText> */}
         
          <FlexDivForBlueDivsOnly>
            {isAI(test) ? (
              <>
                <MyWorkBlueDiv
                  color={data.color}
                  background={"#FFFFFF"}
                  borderColor={data.color}
                >
                  <MyWorkSmallBlueDiv background={data.color}>
                    {type[0]}
                  </MyWorkSmallBlueDiv>
                  <MyWorkBlueDivText color={data.color}>
                    {getScore(test)}
                  </MyWorkBlueDivText>
                </MyWorkBlueDiv>

                <MyWorkBlueDivAi style={{backgroundColor:data.color, border:`1px solid ${data.color}`}}
                  onClick={() => handleOpenScoreCard(test)} // Open AI scorecard
                >
                  <MyWorkSmallBlueDivAi>
                    <MyWorkBlueDivImg alt="" src={AI_Score} />
                  </MyWorkSmallBlueDivAi>
                  <MyWorkBlueDivText>AI SCORE</MyWorkBlueDivText>
                </MyWorkBlueDivAi>
              </>
            ) : (
              <>
                <MyWorkBlueDiv
                  color={data.color}
                  background={"#FFFFFF"}
                  borderColor={data.color}
                  onClick={() => handleOpenScoreCard(test)} // Open non-AI scorecard
                >
                  <MyWorkSmallBlueDiv background={data.color}>
                  {type[0]}
                  </MyWorkSmallBlueDiv>
                  <MyWorkBlueDivText color={data.color}>
                    {getScore(test)}
                  </MyWorkBlueDivText>
                </MyWorkBlueDiv>
              </>
            )}
          </FlexDivForBlueDivsOnly>
        </EndScoreScoresDiv>
      </EndScoreCard>
    ))}


       {selectedTestData && (
        <ScoreCard
          isOpen={scorecardOpen}
          close={handleCloseScoreCard}
          testData={selectedTestData}
          category={type}
          testType={selectedTestData.UserResponse.SubCategory}
          pteType={pteType}
        />
      )}
  </>
  );
};

export default EndTestScoreCard;
