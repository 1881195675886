import React, { useEffect, useState, useRef } from "react";
import TestHeading from "../components/Common/TestHeading";
import MCSLogo from "../assets/images/MCS_Logo.svg";
import { MCS_Subheading } from "../components/Common/Data";
import Navbar from "../components/Navbar/Navbar";
import ButtonList from "../components/Common/ButtonList";
import CommunityScore from "../components/Common/CommunityScore";
import { SWT_MCQCard_Div, SWT_QCard_Div } from "./Style";
import TextCardReadonly from "../components/Writing/TextCardReadonly";
import {
  MCQsComponentData,
  TextCardReadonlyData,
} from "../components/Writing/data";
import MCQsComponent from "../components/Writing/MCQsComponent";
import { FlexDiv } from "../assets/styles/style";
import SidePannel from "../components/Common/SidePannel";
import ReadingAnswerBox from "../components/Common/ReadingAnswerBox";
import ScorePopupReadingMCM from "../components/Reading/ScorePopupReadingMCM";
import { AiScorePopupReadingMCMAData } from "../components/Reading/data";
import { SidePannelBackdrop } from "../components/Common/Style";
import LoadingModal from "../components/Common/LoadingModal";
import { Base_URL } from "../Client/apiURL";

const questionname = "Multiple Choice, Single Answer";

const ReadingMCS = () => {
  const [testQuestions, setTestQuestions] = useState([]);
  const [questionId, setQuestionID] = useState();
  const [questionsData, setQuestionsData] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [showAnswer, setShowAnswer] = useState(false);
  const [scorecardOpen, setScorecardOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [triggerReset, setTriggerReset] = useState(false);
  const [enableSkillsData, setEnableSkillsData] = useState([]);
  const [scoreDataSubmitted, setScoreDataSubmitted] = useState(false);
  const [testQuestionTableId, setTestQuestionTableId] = useState();
  const [myAttemptedQuestionsScore, setMyAttemptedQuestionsScore] =
    useState(null);
  const [marksObtained, setMarksObtained] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState([""]);
  const [shouldOpenScorecard, setShouldOpenScorecard] = useState(false);
  const [isScoreDataReadyForSubmit, setIsScoreDataReadyForSubmit] =
    useState(false);
  const [lastScoreUpdate, setLastScoreUpdate] = useState(Date.now());
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [filterBookmarked, setFilterBookmarked] = useState(false);
  const [filterPrediction, setFilterPrediction] = useState(false);
  const [wantToSortDesc, setWantToSortDesc] = useState(false);
  const [highFrequency, setHighFrequency] = useState(false);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmarkId, setBookmarkId] = useState(null);
  const [isPracticed, setIsPracticed] = useState("all");
  const [testAttemptedCount, setTestAttemptedCount] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const sessionAppliedRef = useRef(false);

  let storedSelectedQuestion;

  // useEffect(() => {
  //   storedSelectedQuestion = JSON.parse(
  //     sessionStorage.getItem("selectedQuestion")
  //   );

  //   if (storedSelectedQuestion) {
  //     // console.log("Stored Question Found:", storedSelectedQuestion);
  //   }

  //   if (
  //     storedSelectedQuestion &&
  //     storedSelectedQuestion.currentPath === window.location.pathname
  //   ) {
  //     setQuestionID(storedSelectedQuestion.questionId || questionId);
  //     setTestQuestionTableId(storedSelectedQuestion.testQuestionTableId || testQuestionTableId);
  //     setCurrentQuestionIndex(storedSelectedQuestion.currentQuestionIndexInParent || 0);
  //     setPage(storedSelectedQuestion.currentPage || 1);
  //     setWantToSortDesc(storedSelectedQuestion.currentSorting || false);
  //     setHighFrequency(storedSelectedQuestion.currentFrequency || false);
  //   } else {
  //     sessionStorage.removeItem("selectedQuestion");
  //   }
  // }, []);

  // useEffect(() => {
  //   const clearstoredSelectedQuestionOnNavigation = () => {
  //     sessionStorage.removeItem('selectedQuestion');
  //   };

  //   window.addEventListener('beforeunload', clearstoredSelectedQuestionOnNavigation);

  //   return () => {
  //     window.removeEventListener('beforeunload', clearstoredSelectedQuestionOnNavigation);
  //   };
  // }, []);

  useEffect(() => {
    const currentRoute = window.location.pathname;
    const validRouteBase = "/reading/";
    if (!currentRoute.startsWith(validRouteBase)) {
      sessionStorage.removeItem("selectedQuestion");
    }
  }, [location]);

  const handleSetLoading = (loadingState) => {
    setIsDataLoading(loadingState);
  };

  useEffect(() => {
    if (questionsData) {
      setIsDataLoading(false);
    }
  }, [questionsData]);

  const updateLocalQuestionBookmark = (
    testQuestionTableId,
    newBookmarkStatus,
    bookmarkId
  ) => {
    setTestQuestions((prevQuestions) => ({
      ...prevQuestions,
      response: prevQuestions.response.map((question) =>
        question.TestQuestionTableId === testQuestionTableId
          ? {
              ...question,
              IsBookMarked: newBookmarkStatus,
              BookMarkedId: newBookmarkStatus ? bookmarkId : null,
            }
          : question
      ),
    }));
  };

  useEffect(() => {
    const question = testQuestions?.response?.find(
      (q) => q.QuestionId === questionId
    );
    if (question) {
      setIsBookmarked(question.IsBookMarked);
      setBookmarkId(question.BookMarkedId);
      setTestAttemptedCount(question.TestAttemptedCount);
    }
  }, [testQuestions, testQuestionTableId]);

  const handleBookmarkChange = (newIsBookmarked, newBookmarkId) => {
    setIsBookmarked(newIsBookmarked);
    setBookmarkId(newBookmarkId);
  };

  useEffect(() => {
    if (
      testQuestions.response &&
      testQuestions.response.length > 0 &&
      currentQuestionIndex < testQuestions.response.length
    ) {
      const question = testQuestions.response[currentQuestionIndex];
      setQuestionID(question.QuestionId);
      setTestQuestionTableId(question.TestQuestionTableId);
    }
  }, [testQuestions, currentQuestionIndex]);

  // const handleNextQuestion = () => {
  //   if (
  //     currentQuestionIndex <
  //     (testQuestions.response ? testQuestions.response.length - 1 : 0)
  //   ) {
  //     setCurrentQuestionIndex(currentQuestionIndex + 1);
  //   } else if (page < totalPages) {
  //     setPage(page + 1);
  //     setCurrentQuestionIndex(0);
  //   } else {
  //     console.log("No more questions to display.");
  //   }
  // };

  // const handlePreviousQuestion = () => {
  //   if (currentQuestionIndex > 0) {
  //     setCurrentQuestionIndex(currentQuestionIndex - 1);
  //   } else if (page > 1) {
  //     setPage(page - 1);
  //     setCurrentQuestionIndex(testQuestions.response.length - 1);
  //   } else {
  //     console.log("No previous questions to display.");
  //   }
  // };

  // const handlePageChange = (newPage) => {
  //   setPage(newPage);
  // };

  const handleNextQuestion = () => {
    if (
      currentQuestionIndex <
      (testQuestions.response ? testQuestions.response.length - 1 : 0)
    ) {
      const nextQuestion = testQuestions.response[currentQuestionIndex + 1];
      setCurrentQuestionIndex(currentQuestionIndex + 1);

      sessionStorage.setItem(
        "selectedQuestion",
        JSON.stringify({
          questionId: nextQuestion.QuestionId,
          testQuestionTableId: nextQuestion.TestQuestionTableId,
          currentPath: window.location.pathname,
          currentQuestionIndexInParent: currentQuestionIndex + 1,
          currentPage: page,
          currentSorting: wantToSortDesc,
          currentFrequency: highFrequency,
          currentPracticed: isPracticed,
        })
      );
    } else if (page < totalPages) {
      setPage(page + 1);
      setCurrentQuestionIndex(0);

      const firstQuestionOnNextPage = testQuestions.response[0];
      setQuestionID(firstQuestionOnNextPage.QuestionId);
      setTestQuestionTableId(firstQuestionOnNextPage.TestQuestionTableId);

      sessionStorage.setItem(
        "selectedQuestion",
        JSON.stringify({
          questionId: firstQuestionOnNextPage.QuestionId,
          testQuestionTableId: firstQuestionOnNextPage.TestQuestionTableId,
          currentPath: window.location.pathname,
          currentQuestionIndexInParent: 0,
          currentPage: page + 1,
          currentSorting: wantToSortDesc,
          currentFrequency: highFrequency,
          currentPracticed: isPracticed,
        })
      );
    } else {
      console.log("No more questions to display.");
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      const previousQuestion = testQuestions.response[currentQuestionIndex - 1];
      setCurrentQuestionIndex(currentQuestionIndex - 1);

      sessionStorage.setItem(
        "selectedQuestion",
        JSON.stringify({
          questionId: previousQuestion.QuestionId,
          testQuestionTableId: previousQuestion.TestQuestionTableId,
          currentPath: window.location.pathname,
          currentQuestionIndexInParent: currentQuestionIndex - 1,
          currentPage: page,
          currentSorting: wantToSortDesc,
          currentFrequency: highFrequency,
          currentPracticed: isPracticed,
        })
      );
    } else if (page > 1) {
      setPage(page - 1);
      setCurrentQuestionIndex(testQuestions.response.length - 1);

      const lastQuestionOnPreviousPage =
        testQuestions.response[testQuestions.response.length - 1];
      setQuestionID(lastQuestionOnPreviousPage.QuestionId);
      setTestQuestionTableId(lastQuestionOnPreviousPage.TestQuestionTableId);

      sessionStorage.setItem(
        "selectedQuestion",
        JSON.stringify({
          questionId: lastQuestionOnPreviousPage.QuestionId,
          testQuestionTableId: lastQuestionOnPreviousPage.TestQuestionTableId,
          currentPath: window.location.pathname,
          currentQuestionIndexInParent: testQuestions.response.length - 1,
          currentPage: page - 1,
          currentSorting: wantToSortDesc,
          currentFrequency: highFrequency,
          currentPracticed: isPracticed,
        })
      );
    } else {
      console.log("No previous questions to display.");
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleMyAttemptedQuestionsScore = (score, openScorecard) => {
    setMyAttemptedQuestionsScore(score);
    if (openScorecard) {
      setShouldOpenScorecard(true);
    }
  };

  useEffect(() => {
    if (shouldOpenScorecard) {
      setScorecardOpen(true);
      setShouldOpenScorecard(false);
    }
  }, [shouldOpenScorecard]);

  useEffect(() => {
    if (
      questionsData &&
      questionsData.response.OptionNames &&
      questionsData.response.AnswerNames
    ) {
      const correct = findMatchingLetters(
        questionsData.response.OptionNames,
        questionsData.response.AnswerNames
      );
      setCorrectAnswers(correct);
    }
  }, [questionsData]);

  // function getQuestionName() {
  //   console.log("test questions in question name fun:", testQuestions);
  //   const questionTemp = testQuestions?.response?.find(
  //     (q) => q.QuestionId === questionId
  //   );
  //   return questionTemp ? questionTemp.QuestionName : "loading";
  // }

  function getQuestionName() {
    const activeQuestionId =
      storedSelectedQuestion &&
      storedSelectedQuestion?.currentPath === window.location.pathname
        ? storedSelectedQuestion?.questionId
        : questionId;
    const questionTemp = testQuestions?.response?.find(
      (q) => q.QuestionId === activeQuestionId
    );

    return questionTemp ? questionTemp.QuestionName : "loading";
  }

  const findMatchingLetters = (bigArray, smallArray) => {
    const matchingLetters = [];

    for (let i = 0; i < bigArray.length; i++) {
      if (smallArray.includes(bigArray[i])) {
        matchingLetters.push(String.fromCharCode(65 + i));
      }
    }
    return matchingLetters;
  };

  function canSubmit() {
    return !(
      Array.isArray(selectedAnswers) &&
      selectedAnswers?.length > 0 &&
      selectedAnswers.some((value) => value !== "")
    );
  }

  function markingFunction(answerList, userAnswers) {
    let score = 0;
    answerList.forEach((correctAnswer) => {
      const isCorrect = userAnswers.includes(correctAnswer);
      if (isCorrect) {
        score += 1;
      }
    });
    return score;
  }

  const handleSearchChange = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
  };

  useEffect(() => {
    setPage(1);
  }, [filterBookmarked, filterPrediction]);

  useEffect(() => {
    const fetchData = async () => {
      const storedSelectedQuestion = JSON.parse(
        sessionStorage.getItem("selectedQuestion")
      );

      // Only apply session storage if it hasn't been applied yet
      if (
        storedSelectedQuestion &&
        storedSelectedQuestion.currentPath === window.location.pathname &&
        !sessionAppliedRef.current
      ) {
        setPage(storedSelectedQuestion.currentPage || 1);
        setWantToSortDesc(storedSelectedQuestion.currentSorting || false);
        setHighFrequency(storedSelectedQuestion.currentFrequency || false);
        setIsPracticed(storedSelectedQuestion.currentPracticed || "all");
        setQuestionID(storedSelectedQuestion.questionId);
        setCurrentQuestionIndex(
          storedSelectedQuestion.currentQuestionIndexInParent
        );
        setTestQuestionTableId(storedSelectedQuestion.testQuestionTableId);
        sessionAppliedRef.current = true; // Mark session as applied
      }

      const encodedQuestionName = encodeURIComponent(questionname);
      const searchQueryParam = searchTerm
        ? `&search_name=${encodeURIComponent(searchTerm)}`
        : "";
      const highFrequencyParam = highFrequency ? `&high_frequency=true` : "";
      const sortDescParam = wantToSortDesc ? "asc" : "desc";
      const isPracticedParam =
        isPracticed === "all" ? "" : `&is_practiced=${isPracticed}`;
      const isPredictionParam = filterPrediction
        ? `&prediction=${filterPrediction}`
        : `&prediction=${false}`;

      const queryParams = `test_name=${encodedQuestionName}&page=${page}&bookmarked=${filterBookmarked}&is_ptecore=false&order_by=${sortDescParam}${highFrequencyParam}${isPracticedParam}${searchQueryParam}${isPredictionParam}`;
      try {
        const url = `${Base_URL}/app/users/test-questions/get-by-name?${queryParams}`;
        const response = await fetch(url, { credentials: "include" });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data.responseCode === 501) {
          logout();
          navigate("/login");
          return;
        }

        setTestQuestions(data);

        // If session data was applied and question exists, set the session question, index, and tableId
        if (storedSelectedQuestion && !sessionAppliedRef.current) {
          const foundQuestion = data.response.find(
            (q) => q.QuestionId === storedSelectedQuestion.questionId
          );
          if (foundQuestion) {
            setQuestionID(storedSelectedQuestion.questionId);
            setCurrentQuestionIndex(
              storedSelectedQuestion.currentQuestionIndexInParent
            );
            setTestQuestionTableId(storedSelectedQuestion.testQuestionTableId);
          } else {
            // If the session question isn't found, fallback to the first question
            const firstQuestion = data.response[0];
            setQuestionID(firstQuestion.QuestionId);
            setTestQuestionTableId(firstQuestion.TestQuestionTableId);
            setCurrentQuestionIndex(0);
          }
          sessionAppliedRef.current = true; // Ensure session application happens once
        }

        const totalQuestions = data.totalQuestions || 1;
        const questionsPerPage = 8;
        setTotalPages(Math.ceil(totalQuestions / questionsPerPage));
      } catch (error) {
        console.error("Failed to fetch test questions:", error);
      }
    };

    fetchData();
  }, [
    page,
    wantToSortDesc,
    searchTerm,
    filterBookmarked,
    filterPrediction,
    isPracticed,
    highFrequency,
  ]);

  //   useEffect(() => {

  //     const fetchData = async () => {
  //       const encodedQuestionName = encodeURIComponent(questionname);
  //       const searchQueryParam = searchTerm ? `&search_name=${encodeURIComponent(searchTerm)}` : "";
  //       const highFrequencyParam = highFrequency ? `&high_frequency=true` : "";
  //       let sortDescParam = wantToSortDesc ? "asec" : "desc";
  //       let isPracticedParam = isPracticed === "all" ? "" : `&is_practiced=${isPracticed}`;
  //       let isPredictionParam = filterPrediction ? `&prediction=${filterPrediction}` : `&prediction=${false}`;

  //       const queryParams = `test_name=${encodedQuestionName}&page=${page}&bookmarked=${filterBookmarked}&is_ptecore=false&order_by=${sortDescParam}${highFrequencyParam}${isPracticedParam}${searchQueryParam}${isPredictionParam}`;

  //       try {
  //         const url = `${Base_URL}/app/users/test-questions/get-by-name?${queryParams}`;
  //         const response = await fetch(url, { credentials: "include" });

  //         if (!response.ok) {
  //           throw new Error(`HTTP error! status: ${response.status}`);
  //         }

  //         const data = await response.json();

  //         if (data.responseCode === 501) {
  //           logout();
  //           navigate("/login");
  //           return;
  //         }

  //       setTestQuestions(data);

  //       // Check if the currently selected question (from session storage) is in the new data
  //       const storedQuestionId = storedSelectedQuestion?.questionId;
  //       const storedTableId = storedSelectedQuestion?.testQuestionTableId;
  //       const foundQuestion = data.response?.find(q => q.QuestionId === storedQuestionId);

  //       if (foundQuestion) {
  //         setQuestionID(storedQuestionId);
  //         setTestQuestionTableId(storedTableId);
  //         setCurrentQuestionIndex(storedSelectedQuestion?.currentQuestionIndexInParent || 0);
  //       } else {
  //         const firstQuestion = data.response[0];
  //         setQuestionID(firstQuestion.QuestionId);
  //         setTestQuestionTableId(firstQuestion.TestQuestionTableId);
  //         setCurrentQuestionIndex(0);
  //       }

  //       const totalQuestions = data.totalQuestions || 1;
  //       const questionsPerPage = 8;
  //       const calculatedTotalPages = Math.ceil(totalQuestions / questionsPerPage);
  //       setTotalPages(calculatedTotalPages);
  //     } catch (error) {
  //       console.error("Failed to fetch test questions:", error.message);
  //     }
  //   };

  //   fetchData();
  // }, [
  //   page,
  //   wantToSortDesc,
  //   searchTerm,
  //   filterBookmarked,
  //   filterPrediction,
  //   isPracticed,
  //   highFrequency,
  // ]);

  // useEffect(() => {
  //   const fetchData = async () => {

  //     const encodedQuestionName = encodeURIComponent(questionname);
  //     const searchQueryParam = searchTerm ? `&search_name=${encodeURIComponent(searchTerm)}` : "";
  //     const highFrequencyParam = highFrequency ? `&high_frequency=true` : "";
  //     let sortDescParam = highFrequency ? "" : `&order_by=${wantToSortDesc ? "asec" : "desc"}`;
  //     let isPracticedParam = isPracticed === "all" ? "" : `&is_practiced=${isPracticed}`;
  //     let isPredictionParam = filterPrediction ? `&prediction=${filterPrediction}` : `&prediction=${false}`;
  //     let isPteCoreParam = false;

  //     let activePage = storedSelectedQuestion?.currentPage || page;

  //     const queryParams = `test_name=${encodedQuestionName}&page=${activePage}&bookmarked=${filterBookmarked}&is_ptecore=${isPteCoreParam}${sortDescParam}${highFrequencyParam}${isPracticedParam}${searchQueryParam}${isPredictionParam}`;

  //     try {
  //       const url = `${Base_URL}/app/users/test-questions/get-by-name?${queryParams}`;
  //       const response = await fetch(url, { credentials: "include" });

  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }

  //       const data = await response.json();

  //       if (data.responseCode === 501) {
  //         logout();
  //         navigate("/login");
  //         return;
  //       }

  //       setTestQuestions(data);
  //       setCurrentQuestionIndex(0);
  //       if (data.response && data.response.length > 0) {

  //         const firstQuestion = data.response[0];
  //         setQuestionID(firstQuestion.QuestionId);
  //         setTestQuestionTableId(firstQuestion.TestQuestionTableId);

  //         // Update session storage with new page and question details
  //         sessionStorage.setItem(
  //           "selectedQuestion",
  //           JSON.stringify({
  //             questionId: firstQuestion.QuestionId,
  //             testQuestionTableId: firstQuestion.TestQuestionTableId,
  //             currentPath: window.location.pathname,
  //             currentQuestionIndexInParent: 0, // Reset to the first question
  //             currentPage: activePage, // Update the page number
  //           })
  //         );

  //         // if (
  //         //   storedSelectedQuestion &&
  //         //   storedSelectedQuestion.currentPath === window.location.pathname
  //         // ) {
  //         //   setQuestionID(storedSelectedQuestion.questionId);
  //         //   setTestQuestionTableId(storedSelectedQuestion.testQuestionTableId);
  //         // } else {
  //         //   setQuestionID(data.response[0].QuestionId);
  //         //   setTestQuestionTableId(data.response[0].TestQuestionTableId);
  //         // }
  //       }

  //       const totalQuestions = data.totalQuestions || 1;
  //       const questionsPerPage = 8;
  //       const calculatedTotalPages = Math.ceil(
  //         totalQuestions / questionsPerPage
  //       );
  //       setTotalPages(calculatedTotalPages);
  //     } catch (error) {
  //       console.error("Failed to fetch test questions:", error.message);
  //     }
  //   };

  //   fetchData();
  // }, [
  //   page,
  //   searchTerm,
  //   filterBookmarked,
  //   filterPrediction,
  //   isPracticed,
  //   wantToSortDesc,
  //   highFrequency,
  // ]);

  useEffect(() => {
    async function submitScoreData() {
      const userResponse = {
        correctAnswers: correctAnswers,
        selectedAnswers: selectedAnswers,
        enableSkillsData: enableSkillsData,
      };

      const payload = {
        test_question_id: testQuestionTableId,
        marks_obtained: marksObtained,
        user_response: JSON.stringify(userResponse),
        time_taken: elapsedTime,
        is_ptecore: false,
      };

      try {
        const response = await fetch(
          `${Base_URL}/app/users/attempted-questions/add`,
          {
            credentials: "include",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        const data = await response.json();
        if (data.responseCode === 200) {
          setScoreDataSubmitted(true);
          setIsScoreDataReadyForSubmit(false);
          setLastScoreUpdate(Date.now());
        } else {
          console.log(data.message);
        }
      } catch (error) {
        console.error("Failed to submit score data:", error);
      }
    }

    const shouldSubmitScore =
      isScoreDataReadyForSubmit &&
      enableSkillsData.length > 0 &&
      elapsedTime &&
      !scoreDataSubmitted;
    if (shouldSubmitScore) {
      submitScoreData();
    }
  }, [
    isScoreDataReadyForSubmit,
    enableSkillsData.length,
    scoreDataSubmitted,
    elapsedTime,
  ]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const scorecardElement = document.getElementById("scorecard");
      if (scorecardElement && !scorecardElement.contains(event.target)) {
        setScorecardOpen(false);

        setSelectedAnswers([]);
        setElapsedTime(0);
        setEnableSkillsData([]);
        setMyAttemptedQuestionsScore(null);
        setMarksObtained(0);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const resetState = () => {
    setSelectedAnswers([]);
    setShowAnswer(false);
    setScorecardOpen(false);
    setIsSubmitted(false);
    setElapsedTime(0);
    setTriggerReset((prevState) => !prevState);
    setEnableSkillsData([]);
    setScoreDataSubmitted(false);
    setMyAttemptedQuestionsScore(null);
    setMarksObtained(0);
    setShouldOpenScorecard(false);
  };

  useEffect(() => {
    resetState();
  }, [questionId]);

  const handleRedo = () => {
    resetState();
  };

  function handleSubmission() {
    setIsSubmitted(true);
    setScorecardOpen(true);
    setIsScoreDataReadyForSubmit(true);
  }

  const calculateDaysDifference = (createdAt) => {
    const currentDate = new Date();
    const createdDate = new Date(createdAt);
    const timeDiff = currentDate - createdDate;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff < 60;
  };

  return (
    <>
      {isDataLoading && <LoadingModal />}
      {scorecardOpen && (
        <FlexDiv
          style={{
            position: "fixed",
            top: " 50%",
            left: " 50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1002",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            width: "100vw",
            height: "100vh",
          }}
        >
          <ScorePopupReadingMCM
            EnableSkills={[
              {
                component: "Choice",
                score: `${markingFunction(
                  findMatchingLetters(
                    questionsData?.response.OptionNames,
                    questionsData?.response.AnswerNames
                  ),
                  selectedAnswers
                )}/1`,
                suggestion: "Excellent!",
              },
            ]}
            SmallScoreCard={[AiScorePopupReadingMCMAData[0].SmallScoreCard[0]]}
            CorrectAnswers={findMatchingLetters(
              questionsData?.response.OptionNames,
              questionsData?.response.AnswerNames
            )}
            selectedAnswers={selectedAnswers}
            answerIsAList={false}
            markingFunction={markingFunction}
            elapsedTime={elapsedTime}
            setEnableSkillsData={setEnableSkillsData}
            myAttemptedQuestionsScore={myAttemptedQuestionsScore}
            setMarksObtained={setMarksObtained}
            questionOptions={questionsData?.response.OptionNames}
            close={setScorecardOpen}
            isOpen={scorecardOpen}
          />
        </FlexDiv>
      )}
      {isSidePanelOpen && <SidePannelBackdrop isOpen={isSidePanelOpen} />}
      <SidePannel
        onToggle={setIsSidePanelOpen}
        testQuestions={testQuestions.response || []}
        totalTestQuestions={testQuestions.totalQuestions}
        heading={"Multiple Choice, Single Answer"}
        setQuestionsData={setQuestionsData}
        questionID={questionId}
        setQuestionID={setQuestionID}
        testQuestionTableId={testQuestionTableId}
        setTestQuestionTableId={setTestQuestionTableId}
        setSelectedAnswers={setSelectedAnswers}
        setTriggerReset={setTriggerReset}
        logo={MCSLogo}
        handleSearchChange={handleSearchChange}
        onPageChange={handlePageChange}
        currentPage={page}
        totalPages={totalPages}
        setFilterBookmarked={setFilterBookmarked}
        setFilterPrediction={setFilterPrediction}
        wantToSortDesc={wantToSortDesc}
        setWantToSortDesc={setWantToSortDesc}
        highFrequency={highFrequency}
        setHighFrequency={setHighFrequency}
        updateLocalQuestionBookmark={updateLocalQuestionBookmark}
        isPracticed={isPracticed}
        setIsPracticed={setIsPracticed}
        setDataLoading={handleSetLoading}
        setCurrentQuestionIndex={setCurrentQuestionIndex}
      />
      <Navbar />
      <FlexDiv style={{ width: "100%" }}>
        <FlexDiv
          style={{ flexDirection: "column", width: "100%", maxWidth: "1880px" }}
        >
          <TestHeading
            logo={MCSLogo}
            heading={"Multiple Choice, Single Answer"}
            subheading={MCS_Subheading}
            serialNo={questionId ? "#" + questionId : "#000"}
            questionName={getQuestionName()}
            remainTime={false}
            onTestSubmit={handleSubmission}
            isSubmitted={isSubmitted}
            setElapsedTime={setElapsedTime}
            triggerReset={triggerReset}
            testQuestionTableId={testQuestionTableId}
            appearedCount={questionsData?.response?.AppearedCount}
            IsBookMarked={isBookmarked}
            BookMarkedId={bookmarkId}
            onBookmarkChange={handleBookmarkChange}
            questionID={questionId}
            dictionaryText={questionsData?.response?.QuestionStatement}
            dictionaryArrayText={questionsData?.response?.OptionNames}
            testAttemptedCount={testAttemptedCount}
            isPrediction={questionsData?.response?.Prediction}
            isNew={calculateDaysDifference(questionsData?.response?.CreatedAt)}
          />
          <SWT_QCard_Div>
            <TextCardReadonly
              id={TextCardReadonlyData[4].id}
              textValue={
                questionsData?.response?.QuestionStatement
                  ? questionsData?.response?.QuestionStatement
                  : ""
              }
            />
          </SWT_QCard_Div>
          <SWT_MCQCard_Div>
            <MCQsComponent
              key={`mcq-component-${triggerReset}`}
              id={MCQsComponentData[0].id}
              question={
                questionsData?.response?.OptionText ||
                "Choose option from the following:"
              }
              answers={
                questionsData?.response?.OptionNames
                  ? questionsData?.response?.OptionNames.map(
                      (item, index) =>
                        `${String.fromCharCode(65 + index)}) ${item}`
                    )
                  : []
              }
              render={MCQsComponentData[1].render}
              userSelectedAnswers={setSelectedAnswers}
            />
          </SWT_MCQCard_Div>
          <ButtonList
            onSubmit={() => {
              handleSubmission();
            }}
            onRedo={() => handleRedo()}
            onAnswer={() => setShowAnswer(!showAnswer)}
            canSubmit={canSubmit}
            isSubmitted={isSubmitted}
            onNext={handleNextQuestion}
            onPrevious={handlePreviousQuestion}
            hasPrevious={currentQuestionIndex > 0 || page > 1}
            hasNext={
              currentQuestionIndex <
                (testQuestions.response
                  ? testQuestions.response.length - 1
                  : 0) || page < totalPages
            }
            handleSearchChange={handleSearchChange}
          />
          {showAnswer && questionsData?.response?.AnswerNames && (
            <ReadingAnswerBox
              answerText={findMatchingLetters(
                questionsData?.response.OptionNames,
                questionsData?.response.AnswerNames
              )}
              addIndex={false}
            />
          )}
          <CommunityScore
            ai_score={false}
            bg="#AD826E"
            ScoreLetter="R"
            formateScore={false}
            totalScore={questionsData?.response?.AnswerNames.length || 1}
            testQuestionTableId={testQuestionTableId}
            onSelectMyScore={handleMyAttemptedQuestionsScore}
            lastScoreUpdate={lastScoreUpdate}
          />
        </FlexDiv>
      </FlexDiv>
    </>
  );
};

export default ReadingMCS;
