import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  AnalyticDivOne,
  MainDiv,
  RefreshDiv,
  RefreshDivText,
  RefreshImg,
} from "./style";
import { FlexDiv } from "../../assets/styles/style";
import Refresh from "../../assets/images/refreshimg.svg";
import AnalyticsCard1 from "./AnalyticsCard1";
import { AnalyticsCard1Data, CardBottomData } from "./data";
import { useMediaQuery } from "@mui/material";
import AnalyticsCard2 from "./AnalyticsCard2";
import CardBottom from "../Home/CardBottom";
import CircularLoader from "../Login/CircularLoader";

import TodayTaskImg1 from "../../assets/todaytaskcard/todaytaskimg1.svg";
import TodayTaskImg2 from "../../assets/todaytaskcard/todaytaskimg2.svg";
import TodayTaskImg3 from "../../assets/todaytaskcard/todaytaskimg3.svg";
import TodayTaskImg4 from "../../assets/todaytaskcard/todaytaskimg4.svg";
import MockTestImg from "../../assets/todaytaskcard/mocktestimg.svg";
import LoadingModal from "../Common/LoadingModal";
import { useAuth } from "../../authentication/Auth";
import { Base_URL } from "../../Client/apiURL";

const getInitialAnalyticsStaticData = (pteType) => {
  const analyticsBaseData = {
    speaking: {
      id: 1,
      imageSrc: TodayTaskImg4,
      titleText: "Speaking",
      titleTextColor: "#49D7F2",
      marksTextColor: "#66E0F7CC",
      barBgColor: "rgba(73, 215, 242, 0.2)",
      totalQuestions: 15,
      totalAttemptedQuestions: 8,
      categoriesData: [
        {
          CategoryName: "Read Aloud",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "67",
          chunkCount: "10",
        },
        {
          CategoryName: "Repeat Sentence",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "70",
          chunkCount: "10",
        },
        {
          CategoryName: "Describe Image",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "20",
          chunkCount: "10",
        },
        {
          CategoryName: "Re-Tell Lecture",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "50",
          chunkCount: "10",
        },
        {
          CategoryName: "Answer Short Question",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "80",
          chunkCount: "10",
        },
      ],
    },
    listening: {
      id: 2,
      imageSrc: TodayTaskImg2,
      titleText: "Listening",
      titleTextColor: "#868EAF",
      marksTextColor: "#868EAFCC",
      barBgColor: "rgba(134, 142, 175, 0.2)",
      totalQuestions: 15,
      totalAttemptedQuestions: 8,
      categoriesData: [
        {
          CategoryName: "Summarize Spoken Text",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "40",
          chunkCount: "10",
        },
        {
          CategoryName: "Multiple Choice, Multiple Answers",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "40",
          chunkCount: "10",
        },
        {
          CategoryName: "Multiple Choice, Single Answer",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "40",
          chunkCount: "10",
        },
        {
          CategoryName: "Highlight Incorrect Words",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "40",
          chunkCount: "10",
        },
        {
          CategoryName: "Fill in the Blanks",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "40",
          chunkCount: "10",
        },
        {
          CategoryName: "Write from Dictation",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "40",
          chunkCount: "10",
        },
        {
          CategoryName: "Highlight Correct Summary",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "40",
          chunkCount: "10",
        },
        {
          CategoryName: "Select Missing Word",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "40",
          chunkCount: "10",
        },
      ],
    },
    writing: {
      id: 3,
      imageSrc: TodayTaskImg3,
      titleText: "Writing",
      titleTextColor: "#FF5D5D",
      marksTextColor: "#FF5D5DCC",
      barBgColor: "rgba(255, 93, 93, 0.2)",
      totalQuestions: 15,
      totalAttemptedQuestions: 8,
      categoriesData: [
        {
          CategoryName: "Summarize Written Text",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "50",
          chunkCount: "10",
        },
        {
          CategoryName: "Write Essay",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "50",
          chunkCount: "10",
        },
      ],
    },
    reading: {
      id: 4,
      imageSrc: TodayTaskImg1,
      titleText: "Reading",
      titleTextColor: "#AD826E",
      marksTextColor: "#AD826ECC",
      barBgColor: "rgba(173, 130, 110, 0.2)",
      totalQuestions: 15,
      totalAttemptedQuestions: 8,
      categoriesData: [
        {
          CategoryName: "Reading & Writing: Fill in the Blanks",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "80",
          chunkCount: "10",
        },
        {
          CategoryName: "Reading: Fill in the Blanks",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "80",
          chunkCount: "10",
        },
        {
          CategoryName: "Multiple Choice, Multiple Answers",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "80",
          chunkCount: "10",
        },
        {
          CategoryName: "Re-order Paragraphs",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "80",
          chunkCount: "10",
        },
        {
          CategoryName: "Multiple Choice, Single Answer",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "80",
          chunkCount: "10",
        },
      ],
    },
    mockTest: {
      id: 5,
      imageSrc: MockTestImg,
      titleText: "Mock Test",
      titleTextColor: "#FD3C65",
      marksTextColor: "#FD3C65CC",
      barBgColor: "rgba(253, 60, 101, 0.2)",
      totalQuestions: 15,
      totalAttemptedQuestions: 8,
      categoriesData: [
        {
          CategoryName: "Full Mock Tests",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "90",
          chunkCount: "10",
        },

        {
          CategoryName: "Sectional Mock Tests",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "90",
          chunkCount: "10",
        },
      ],
    },
  };

  if (pteType === "pte core") {
    analyticsBaseData.speaking = {
      id: 1,
      imageSrc: TodayTaskImg4,
      titleText: "Speaking",
      titleTextColor: "#49D7F2",
      marksTextColor: "#66E0F7CC",
      barBgColor: "rgba(73, 215, 242, 0.2)",
      totalQuestions: 15,
      totalAttemptedQuestions: 8,
      categoriesData: [
        {
          CategoryName: "Read Aloud",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "67",
          chunkCount: "10",
        },
        {
          CategoryName: "Repeat Sentence",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "70",
          chunkCount: "10",
        },
        {
          CategoryName: "Describe Image",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "20",
          chunkCount: "10",
        },
        {
          CategoryName: "Respond to a Situation",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "50",
          chunkCount: "10",
        },
        {
          CategoryName: "Answer Short Question",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "80",
          chunkCount: "10",
        },
      ],
    };
    analyticsBaseData.listening = {
      id: 2,
      imageSrc: TodayTaskImg2,
      titleText: "Listening",
      titleTextColor: "#868EAF",
      marksTextColor: "#868EAFCC",
      barBgColor: "rgba(134, 142, 175, 0.2)",
      totalQuestions: 15,
      totalAttemptedQuestions: 8,
      categoriesData: [
        {
          CategoryName: "Summarize Spoken Text",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "40",
          chunkCount: "10",
        },
        {
          CategoryName: "Multiple Choice, Multiple Answers",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "40",
          chunkCount: "10",
        },
        {
          CategoryName: "Multiple Choice, Single Answer",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "40",
          chunkCount: "10",
        },
        {
          CategoryName: "Highlight Incorrect Words",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "40",
          chunkCount: "10",
        },
        {
          CategoryName: "Fill in the Blanks",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "40",
          chunkCount: "10",
        },
        {
          CategoryName: "Write from Dictation",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "40",
          chunkCount: "10",
        },
        {
          CategoryName: "Select Missing Word",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "40",
          chunkCount: "10",
        },
      ],
    };
    analyticsBaseData.writing = {
      id: 3,
      imageSrc: TodayTaskImg3,
      titleText: "Writing",
      titleTextColor: "#FF5D5D",
      marksTextColor: "#FF5D5DCC",
      barBgColor: "rgba(255, 93, 93, 0.2)",
      totalQuestions: 15,
      totalAttemptedQuestions: 8,
      categoriesData: [
        {
          CategoryName: "Summarize Written Text",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "50",
          chunkCount: "10",
        },
        {
          CategoryName: "Write Email",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "50",
          chunkCount: "10",
        },
      ],
    };
    analyticsBaseData.reading = {
      id: 4,
      imageSrc: TodayTaskImg1,
      titleText: "Reading",
      titleTextColor: "#AD826E",
      marksTextColor: "#AD826ECC",
      barBgColor: "rgba(173, 130, 110, 0.2)",
      totalQuestions: 15,
      totalAttemptedQuestions: 8,
      categoriesData: [
        {
          CategoryName: "Reading & Writing: Fill in the Blanks",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "80",
          chunkCount: "10",
        },
        {
          CategoryName: "Reading: Fill in the Blanks",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "80",
          chunkCount: "10",
        },
        {
          CategoryName: "Multiple Choice, Multiple Answers",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "80",
          chunkCount: "10",
        },
        {
          CategoryName: "Re-order Paragraphs",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "80",
          chunkCount: "10",
        },
        {
          CategoryName: "Multiple Choice, Single Answer",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "80",
          chunkCount: "10",
        },
      ],
    };
    analyticsBaseData.mockTest = {
      id: 5,
      imageSrc: MockTestImg,
      titleText: "Mock Test",
      titleTextColor: "#FD3C65",
      marksTextColor: "#FD3C65CC",
      barBgColor: "rgba(253, 60, 101, 0.2)",
      totalQuestions: 15,
      totalAttemptedQuestions: 8,
      categoriesData: [
        {
          CategoryName: "Full Mock Tests",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "90",
          chunkCount: "10",
        },

        {
          CategoryName: "Sectional Mock Tests",
          TotalCount: "A",
          attemptedcount: "N",
          totalProgress: "90",
          chunkCount: "10",
        },
      ],
    };
  }

  return analyticsBaseData;
};

const TestProgress = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const isTab = useMediaQuery("(max-width:700px)");
  const isMobile = useMediaQuery("(max-width:450px)");
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);

  const [pteType, setPteType] = useState(
    localStorage.getItem("pte-type") || "pte academic"
  );

  const [analyticsStaticData, setAnalyticsStaticData] = useState(
    getInitialAnalyticsStaticData(pteType)
  );

  const normalizeName = (name) => {
    return name
      .toLowerCase()
      .replace(/[-: ]+/g, "")
      .replace(/^listening|reading|writing|speaking/, "")
      .replace("retell", "retell");
  };

  const updateAnalyticsStaticData = (data) => {
    setAnalyticsStaticData((prevState) => {
      const analysis = data.Analysis
        ? data.Analysis.user_analysis
        : data.user_analysis;

      if (!analysis) {
        console.log("No analysis data found, returning previous state");
        return prevState;
      }

      const updatedData = { ...prevState };
      for (const section in analysis) {
        if (updatedData[section.toLowerCase()]) {
          const sectionData = analysis[section];
          const tests = sectionData.Tests;

          updatedData[section.toLowerCase()].totalQuestions =
            sectionData["Total Questions"];
          updatedData[section.toLowerCase()].totalAttemptedQuestions =
            sectionData["Total Attempted Questions"];

          updatedData[section.toLowerCase()].categoriesData = updatedData[
            section.toLowerCase()
          ].categoriesData.map((category) => {
            const matchingCategoryKey = Object.keys(tests).find(
              (testKey) =>
                normalizeName(testKey) === normalizeName(category.CategoryName)
            );
            if (matchingCategoryKey) {
              const matchingCategory = tests[matchingCategoryKey];
              return {
                ...category,
                TotalCount: matchingCategory.TotalCount,
                attemptedcount: matchingCategory.attemptedcount,
              };
            }
            return category;
          });
        }
      }
      return updatedData;
    });
  };

  const fetchData = async (url) => {
    // setIsLoading(true);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      const data = await response.json();

      if (data.responseCode === 200 && data.response) {
        updateAnalyticsStaticData(data.response);
        if (data.response.LastUpdated) {
          setLastUpdated(data.response.LastUpdated);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    const is_ptecore = pteType === "pte academic" ? false : true;
    const url = `${Base_URL}/app/users/stored-analysis?is_ptecore=${is_ptecore}`;
    fetchData(url);
  }, []);

  const handleRefresh = async () => {
    const is_ptecore = pteType === "pte academic" ? false : true;
    const url = `${Base_URL}/app/users/user-analysis?is_ptecore=${is_ptecore}`;

    setIsRefreshLoading(true);
    setIsLoading(true);

    const headers = {
      method: "GET",
      "Content-Type": "application/json",
      credentials: "include",
    };

    try {
      const response = await fetch(url, headers);
      const data = await response.json();
      if (data.responseCode === 200 && data.response) {
        console.log("handleRefresh Data:", data.response);
        updateAnalyticsStaticData(data.response);
        setLastUpdated(new Date().toISOString());
      } else if (data.responseCode === 501) {
        logout();
        navigate("/login");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsRefreshLoading(false);
      setIsLoading(false);
    }
  };

  return (
    <FlexDiv
      style={{ width: "100%", flexDirection: "column", maxWidth: "1680px" }}
    >
      <MainDiv>
        <FlexDiv
          style={{
            justifyContent: "space-between",
          }}
        >
          <RefreshDiv onClick={handleRefresh}>
            {isLoading ? (
              <FlexDiv style={{ gap: "0.5rem" }}>
                <CircularLoader disableShrink size={18} color={"#996cfe"} />
                <RefreshDivText>Refreshing...</RefreshDivText>
              </FlexDiv>
            ) : (
              <>
                <RefreshImg alt="" src={Refresh} />
                <RefreshDivText>Refresh Analytics</RefreshDivText>
              </>
            )}
          </RefreshDiv>
          <RefreshDiv
            style={{
              border: isTab ? "none" : "",
            }}
          >
            {/* <RefreshDivText
              style={{
                fontWeight: "400",
                textAlign: "right",
              }}
            >
              Last updated on{" "}
              {lastUpdated ? new Date(lastUpdated).toLocaleDateString() : "N/A"}
            </RefreshDivText> */}
            <RefreshDivText style={{ fontWeight: "400", textAlign: "right" }}>
              Last updated on{" "}
              {lastUpdated
                ? new Date(lastUpdated).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: true,
                  })
                : "N/A"}
            </RefreshDivText>
          </RefreshDiv>
        </FlexDiv>
        {!isTab ? (
          <>
            <FlexDiv
              style={{
                marginTop: "20px",
              }}
            >
              {isRefreshLoading && <LoadingModal />}
              {AnalyticsCard1Data.map((data, index) => (
                <AnalyticsCard1
                  key={data.id}
                  count={data.count}
                  TextColor={data.TextColor}
                  percentage={data.percentage}
                  percentageBackgroundColor={data.percentageBackgroundColor}
                  Question={data.Question}
                  totalProgress={data.totalProgress}
                  chunkCount={data.chunkCount}
                  borderRadius={data.borderRadius}
                />
              ))}
            </FlexDiv>
            <FlexDiv
              style={{
                marginTop: "20px",
                alignItems: "flex-start",
                gap: "20px",
              }}
            >
              <FlexDiv
                style={{ flexDirection: "column", gap: "14px", width: "100%" }}
              >
                {/* SPEAKING */}
                <AnalyticsCard2
                  key={analyticsStaticData.speaking.id}
                  imageSrc={analyticsStaticData.speaking.imageSrc}
                  titleText={analyticsStaticData.speaking.titleText}
                  titleTextColor={analyticsStaticData.speaking.titleTextColor}
                  marksTextColor={analyticsStaticData.speaking.marksTextColor}
                  barBgColor={analyticsStaticData.speaking.barBgColor}
                  categoriesData={analyticsStaticData.speaking.categoriesData}
                  totalProgress={analyticsStaticData.speaking.totalProgress}
                  chunkCount={analyticsStaticData.speaking.chunkCount}
                  widthOne={"35px"}
                />
                <AnalyticsCard2
                  key={analyticsStaticData.listening.id}
                  imageSrc={analyticsStaticData.listening.imageSrc}
                  titleText={analyticsStaticData.listening.titleText}
                  titleTextColor={analyticsStaticData.listening.titleTextColor}
                  marksTextColor={analyticsStaticData.listening.marksTextColor}
                  barBgColor={analyticsStaticData.listening.barBgColor}
                  categoriesData={analyticsStaticData.listening.categoriesData}
                  totalProgress={analyticsStaticData.listening.totalProgress}
                  chunkCount={analyticsStaticData.listening.chunkCount}
                  widthOne={"35px"}
                />
              </FlexDiv>
              <AnalyticDivOne>
                <AnalyticsCard2
                  key={analyticsStaticData.writing.id}
                  imageSrc={analyticsStaticData.writing.imageSrc}
                  titleText={analyticsStaticData.writing.titleText}
                  titleTextColor={analyticsStaticData.writing.titleTextColor}
                  marksTextColor={analyticsStaticData.writing.marksTextColor}
                  barBgColor={analyticsStaticData.writing.barBgColor}
                  categoriesData={analyticsStaticData.writing.categoriesData}
                  totalProgress={analyticsStaticData.writing.totalProgress}
                  chunkCount={analyticsStaticData.writing.chunkCount}
                  widthOne={"35px"}
                />
                <AnalyticsCard2
                  key={analyticsStaticData.reading.id}
                  imageSrc={analyticsStaticData.reading.imageSrc}
                  titleText={analyticsStaticData.reading.titleText}
                  titleTextColor={analyticsStaticData.reading.titleTextColor}
                  marksTextColor={analyticsStaticData.reading.marksTextColor}
                  barBgColor={analyticsStaticData.reading.barBgColor}
                  categoriesData={analyticsStaticData.reading.categoriesData}
                  totalProgress={analyticsStaticData.reading.totalProgress}
                  chunkCount={analyticsStaticData.reading.chunkCount}
                  widthOne={"35px"}
                />
                <AnalyticsCard2
                  key={analyticsStaticData.mockTest.id}
                  imageSrc={analyticsStaticData.mockTest.imageSrc}
                  titleText={analyticsStaticData.mockTest.titleText}
                  titleTextColor={analyticsStaticData.mockTest.titleTextColor}
                  marksTextColor={analyticsStaticData.mockTest.marksTextColor}
                  barBgColor={analyticsStaticData.mockTest.barBgColor}
                  categoriesData={analyticsStaticData.mockTest.categoriesData}
                  totalProgress={analyticsStaticData.mockTest.totalProgress}
                  chunkCount={analyticsStaticData.mockTest.chunkCount}
                  widthOne={"35px"}
                />
              </AnalyticDivOne>
            </FlexDiv>
            <FlexDiv
              style={{
                flexDirection: "column",
                gap: "16px",
                marginTop: "1.5rem",
              }}
            >
              <FlexDiv
                style={{
                  // justifyContent:'space-between',
                  width: "100%",
                  gap: "1rem",
                }}
              >
                <CardBottom
                  key={CardBottomData[0].id}
                  imageSrc={CardBottomData[0].imageSrc}
                  text={CardBottomData[0].text}
                  text_borderColor={CardBottomData[0].text_borderColor}
                  backgroundColor={CardBottomData[0].backgroundColor}
                  url={CardBottomData[0].url}
                />
                <CardBottom
                  key={CardBottomData[1].id}
                  imageSrc={CardBottomData[1].imageSrc}
                  text={CardBottomData[1].text}
                  text_borderColor={CardBottomData[1].text_borderColor}
                  backgroundColor={CardBottomData[1].backgroundColor}
                  url={CardBottomData[1].url}
                />
                <CardBottom
                  key={CardBottomData[2].id}
                  imageSrc={CardBottomData[2].imageSrc}
                  text={CardBottomData[2].text}
                  text_borderColor={CardBottomData[2].text_borderColor}
                  backgroundColor={CardBottomData[2].backgroundColor}
                  url={CardBottomData[2].url}
                />
                <CardBottom
                  key={CardBottomData[3].id}
                  imageSrc={CardBottomData[3].imageSrc}
                  text={CardBottomData[3].text}
                  text_borderColor={CardBottomData[3].text_borderColor}
                  backgroundColor={CardBottomData[3].backgroundColor}
                  url={CardBottomData[3].url}
                />
              </FlexDiv>
              <FlexDiv
                style={{
                  width: "100%",
                  gap: "1rem",
                }}
              >
                <CardBottom
                  key={CardBottomData[4].id}
                  imageSrc={CardBottomData[4].imageSrc}
                  text={CardBottomData[4].text}
                  text_borderColor={CardBottomData[4].text_borderColor}
                  backgroundColor={CardBottomData[4].backgroundColor}
                  url={CardBottomData[4].url}
                />
                <CardBottom
                  key={CardBottomData[5].id}
                  imageSrc={CardBottomData[5].imageSrc}
                  text={CardBottomData[5].text}
                  text_borderColor={CardBottomData[5].text_borderColor}
                  backgroundColor={CardBottomData[5].backgroundColor}
                  url={CardBottomData[5].url}
                />
                <CardBottom
                  key={CardBottomData[6].id}
                  imageSrc={CardBottomData[6].imageSrc}
                  text={CardBottomData[6].text}
                  text_borderColor={CardBottomData[6].text_borderColor}
                  backgroundColor={CardBottomData[6].backgroundColor}
                  url={CardBottomData[6].url}
                />
                <CardBottom
                  key={CardBottomData[7].id}
                  imageSrc={CardBottomData[7].imageSrc}
                  text={CardBottomData[7].text}
                  text_borderColor={CardBottomData[7].text_borderColor}
                  backgroundColor={CardBottomData[7].backgroundColor}
                  url={CardBottomData[7].url}
                />
              </FlexDiv>
            </FlexDiv>
          </>
        ) : (
          <div
            style={{
              marginTop: "20px",
            }}
          >
            {AnalyticsCard1Data.map((data, index) => (
              <AnalyticsCard1
                key={data.id}
                count={data.count}
                TextColor={data.TextColor}
                percentage={data.percentage}
                percentageBackgroundColor={data.percentageBackgroundColor}
                Question={data.Question}
                totalProgress={data.totalProgress}
                chunkCount={data.chunkCount}
                borderRadius={data.borderRadius}
                marginBottom={data.marginBottom}
              />
            ))}
          </div>
        )}
      </MainDiv>
      {isTab && (
        <>
          {isRefreshLoading && <LoadingModal />}
          <FlexDiv
            style={{
              marginTop: "20px",
              flexDirection: "column",
              gap: "10px",
              width: "95%",
            }}
          >
            {/* {AnalyticsStaticData.map((data, index) => (
              <AnalyticsCard2
                key={data.id}
                imageSrc={data.imageSrc}
                titleText={data.titleText}
                titleTextColor={data.titleTextColor}
                marksTextColor={data.marksTextColor}
                barBgColor={data.barBgColor}
                categoriesData={data.categoriesData}
                totalProgress={data.totalProgress}
                chunkCount={data.chunkCount}
              />
            ))} */}
            {Object.values(analyticsStaticData).map((data, index) => (
              <AnalyticsCard2
                key={index}
                imageSrc={data.imageSrc}
                titleText={data.titleText}
                titleTextColor={data.titleTextColor}
                marksTextColor={data.marksTextColor}
                barBgColor={data.barBgColor}
                categoriesData={data.categoriesData}
                totalProgress={data.totalProgress}
                chunkCount={data.chunkCount}
              />
            ))}
          </FlexDiv>
          <FlexDiv
            style={{
              marginTop: "20px",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <FlexDiv
              style={{
                gap: "1rem",
                width: "100%",
              }}
            >
              <CardBottom
                key={CardBottomData[0].id}
                imageSrc={CardBottomData[0].imageSrc}
                text={CardBottomData[0].text}
                text_borderColor={CardBottomData[0].text_borderColor}
                backgroundColor={CardBottomData[0].backgroundColor}
                url={CardBottomData[0].url}
              />
              <CardBottom
                key={CardBottomData[1].id}
                imageSrc={CardBottomData[1].imageSrc}
                text={CardBottomData[1].text}
                text_borderColor={CardBottomData[1].text_borderColor}
                backgroundColor={CardBottomData[1].backgroundColor}
                url={CardBottomData[1].url}
              />
            </FlexDiv>
            <FlexDiv
              style={{
                width: "100%",
                gap: "1rem",
              }}
            >
              <CardBottom
                key={CardBottomData[2].id}
                imageSrc={CardBottomData[2].imageSrc}
                text={CardBottomData[2].text}
                text_borderColor={CardBottomData[2].text_borderColor}
                backgroundColor={CardBottomData[2].backgroundColor}
                url={CardBottomData[2].url}
              />
              <CardBottom
                key={CardBottomData[3].id}
                imageSrc={CardBottomData[3].imageSrc}
                text={CardBottomData[3].text}
                text_borderColor={CardBottomData[3].text_borderColor}
                backgroundColor={CardBottomData[3].backgroundColor}
                url={CardBottomData[3].url}
              />
            </FlexDiv>
            <FlexDiv
              style={{
                width: "100%",
                gap: "1rem",
              }}
            >
              <CardBottom
                key={CardBottomData[4].id}
                imageSrc={CardBottomData[4].imageSrc}
                text={CardBottomData[4].text}
                text_borderColor={CardBottomData[4].text_borderColor}
                backgroundColor={CardBottomData[4].backgroundColor}
                url={CardBottomData[4].url}
              />
              <CardBottom
                key={CardBottomData[5].id}
                imageSrc={CardBottomData[5].imageSrc}
                text={CardBottomData[5].text}
                text_borderColor={CardBottomData[5].text_borderColor}
                backgroundColor={CardBottomData[5].backgroundColor}
                url={CardBottomData[5].url}
              />
            </FlexDiv>
            <FlexDiv
              style={{
                width: "100%",
                gap: "1rem",
              }}
            >
              <CardBottom
                key={CardBottomData[6].id}
                imageSrc={CardBottomData[6].imageSrc}
                text={CardBottomData[6].text}
                text_borderColor={CardBottomData[6].text_borderColor}
                backgroundColor={CardBottomData[6].backgroundColor}
                url={CardBottomData[6].url}
              />
              <CardBottom
                key={CardBottomData[7].id}
                imageSrc={CardBottomData[7].imageSrc}
                text={CardBottomData[7].text}
                text_borderColor={CardBottomData[7].text_borderColor}
                backgroundColor={CardBottomData[7].backgroundColor}
                url={CardBottomData[7].url}
              />
            </FlexDiv>
          </FlexDiv>
        </>
      )}
    </FlexDiv>
  );
};

export default TestProgress;
